// Hook permettant la requête simple de l'api, sans authentification
const useRestaurantRequest = () => {
  const fetchRestaurantData = async (url, options) => {
    // On récupére la réponse renvoyée par le serveur
    const response = await fetch(url, options);

    // On récupère les données associées à cette réponse
    const data = await response.json();

    if (response.ok) {
      return { data, response };
    }
    return { data: null, response };
  };

  return { fetchRestaurantData };
};

export default useRestaurantRequest;

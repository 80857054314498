// Contient tous les labels possibles à attribuer à l'input de recherche
const descriptions = {
  users: 'Utilisateur à rechercher',
  ingredients: 'Ingrédient à rechercher',
  dishes: 'Plat à rechercher',
  dishesPart: 'Ingrédient à ajouter',
  menus: 'Menu à rechercher',
  menusPart: 'Plat à ajouter',
};

export default descriptions;

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled, { keyframes } from 'styled-components';

// On abandonne l'idée de l'animation pour intégrer plus facilement les dots de navigation
const scrollTwoToLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  16.66% {
    transform: translateX(-100%);
  }
  33.3% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  66.6% {
    transform: translateX(-200%);
  }
  83.3% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;

const scrollThreeToLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(-100%);
  }
  37.5% {
    transform: translateX(-200%);
  }
  50% {
    transform: translateX(-200%);
  }
  62.5% {
    transform: translateX(-300%);
  }
  75% {
    transform: translateX(-300%);
  }
  87.5% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;

const scrollFourToLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-100%);
  }
  20% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(-200%);
  }
  40% {
    transform: translateX(-200%);
  }
  50% {
    transform: translateX(-300%);
  }
  60% {
    transform: translateX(-300%);
  }
  70% {
    transform: translateX(-400%);
  }
  80% {
    transform: translateX(-400%);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`;

const CarouselStyled = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  
`;

/*
  Comment faire en sorte d'avoir une animation et une transition en même temps ?
  Une transition peut être pilotée par un state d'index.
  Mais transition et animation sur un même composant ne semblent pas faire bon ménage
 */
const ImageWrapper = styled.div`
  min-width: 380px;
  transition: transform 2s ease;
  transform: translateX(${(props) => props.$transitionIndex}%);
  /* animation: ${scrollTwoToLeft} 12s 2s infinite; */

  @media screen and (max-width: 1200px) {
    min-width: 33.3%;
  }
  @media screen and (max-width: 992px) {
    min-width: 50%;
    /* animation: ${scrollThreeToLeft} 16s 2s infinite; */
  }
  @media screen and (max-width: 576px) {
    min-width: 100%;
    /* animation: ${scrollFourToLeft} 20s 2s infinite; */
  }
`;

const ImageItem = styled.div`
  position: relative;
  margin: 15px;
  border: 4px solid white;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const ImageDescription = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 70%;
  height: auto;
  color: white;
  text-align: center;
  margin: auto;
  padding: 10px;
  border: 4px solid white;
  z-index: 9999;
  transform-origin: center;
  transform: ${(props) => (props.$isHovered === 'true' ? 'scale(1)' : 'scale(0)')};
  opacity: ${(props) => (props.$isHovered === 'true' ? 1 : 0)};
  pointer-events: ${(props) => (props.$isHovered === 'true' ? 'auto' : 'none')};

  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ImageTitle = styled.div`
  font-size: 18px;
`;

const ImageText = styled.div`
  font-style: italic;
`;

const ImageSeparator = styled.div`
  height: 1px;
  width: 60%;
  margin: 15px auto 10px auto;
  background-color: #ffffff;
`;

const ImageCarousel = styled.img`
  width: 100%;
  height: auto;
`;

const DotsWrapper = styled.div`
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Dots = styled.div`
  width: 16px;
  height: 16px;
  margin: 7px;
  border: 2px solid white;
  border-radius: 20px;
`;

function SpecialCarousel({ carouselDatas }) {
  const [isHoveredArray, setIsHoveredArray] = useState(carouselDatas.map(() => false));
  const [transitionIndex, setTransitionIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [shouldRestartInterval, setShouldRestartInterval] = useState(true);

  const changeTransitionIndex = (index) => {
    setTransitionIndex(index);
    setShouldRestartInterval(false);
    setTimeout(() => {
      setShouldRestartInterval(true);
    }, 2000);
  };

  const handleMouseEnter = (index) => {
    const updatedHoveredArray = [...isHoveredArray];
    updatedHoveredArray[index] = true;
    setIsHoveredArray(updatedHoveredArray);
  };

  const handleMouseLeave = (index) => {
    const updatedHoveredArray = [...isHoveredArray];
    updatedHoveredArray[index] = false;
    setIsHoveredArray(updatedHoveredArray);

    // Si l'élément n'est pas survolé, réinitialiser tous les états survolés
    setIsHoveredArray(Array(carouselDatas.length).fill(false));
  };

  const dotsCount = () => {
    if (window.innerWidth >= 992) {
      return [0, -200];
    } if (window.innerWidth >= 576 && window.innerWidth < 992) {
      return [0, -200, -300];
    }
    return [0, -100, -200, -300, -400];
  };

  const count = dotsCount();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused && shouldRestartInterval) {
        setTransitionIndex((prevIndex) => {
          if (window.innerWidth >= 992) {
            return prevIndex === -200 ? 0 : prevIndex - 100;
          }
          if (window.innerWidth >= 576 && window.innerWidth < 992) {
            return prevIndex === -300 ? 0 : prevIndex - 100;
          }
          return prevIndex === -400 ? 0 : prevIndex - 100;
        });
      }

      setIsPaused(!isPaused);
    }, 2000);

    return () => clearInterval(interval);
  }, [isPaused, shouldRestartInterval]);

  return (
    <>
      <CarouselStyled>
        {carouselDatas.map((carouselData) => (
          <ImageWrapper key={carouselData.id} $transitionIndex={transitionIndex}>
            <ImageItem
              className="carousel"
              onMouseEnter={() => handleMouseEnter(carouselData.id)}
              onMouseLeave={() => handleMouseLeave(carouselData.id)}
            >
              <ImageCarousel src={`https://ti-pei-gourmand.fr/images/${carouselData['@type'] === 'Dish' ? 'dishes' : 'menus'}/${carouselData.imageName}`} alt={`img_${carouselData.id}`} />
              <ImageDescription $isHovered={isHoveredArray[carouselData.id] ? 'true' : 'false'}>
                <ImageTitle>
                  {carouselData.title}
                </ImageTitle>
                <ImageSeparator />
                <ImageText>
                  {carouselData.description}
                </ImageText>
              </ImageDescription>
            </ImageItem>
          </ImageWrapper>
        ))}
      </CarouselStyled>
      <DotsWrapper>
        {Array(count.length).fill().map((_, index) => (
          <Dots
            className={`${((count[index] >= transitionIndex) && (count[index + 1] < transitionIndex)) || ((index === count.length - 1) && (count[index] === transitionIndex)) ? 'dotscar' : ''}`}
            key={index}
            onClick={() => changeTransitionIndex(count[index])}
          />
        ))}
      </DotsWrapper>
    </>
  );
}

SpecialCarousel.propTypes = {
  carouselDatas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      '@type': PropTypes.string,
      imageName: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,

    }),
  ).isRequired,
};

export default SpecialCarousel;

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';
import descriptions from '../../../utils/helpers/Searcher';

function Searcher({
  name, // Nom de l'entité associée au searcher
  searchTerm, // Terme actuellement recherché
  setSearchTerm, // Modifie le terme recherché
  allEntities, // Tableau d'objets représentant toutes les entités à rechercher
  setPartEntities, // Modifie l'objet représentant l'entité trouvée via l'input de recherche
  disabled = false, // Booléen permettant de savoir si on a les droits de modification
}) {
  /*
    State contenant l'objet représentant l'entité trouvée via l'input de recherche
    Permet aussi l'autosuggestion du navigateur
  */
  const [searchResults, setSearchResults] = useState([]);

  /*
    Méthode filtrant mon tableau d'entités en ne gardant que
    celles qui commencent comme le terme de recherche entré
  */
  const filterResults = (query) => {
    const filteredResults = allEntities.filter(
      (entity) => {
        if (name === 'users') {
          return entity.realName.toLowerCase().startsWith(query.toLowerCase());
        }
        return entity.title.toLowerCase().startsWith(query.toLowerCase());
      },
    );
    // Dans certains cas on utilisera le state pour l'autosuggestion
    if (name === 'dishesPart' || name === 'menusPart') {
      setSearchResults(filteredResults);
    } else {
      setPartEntities(filteredResults);
    }
  };

  // Méthode permettant de vérifier si le terme entré est exactement correspondant à une entité
  const handleChange = (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (name === 'dishesPart' || name === 'menusPart') {
      // Vérifie si le terme correspond exactement à l'une des entités
      const tempSelectedEntity = allEntities.find(
        (entity) => entity.title.toLowerCase() === query.toLowerCase(),
      );
      if (tempSelectedEntity) {
        setPartEntities(tempSelectedEntity);
      } else {
        setPartEntities(null);
      }
    }
    filterResults(query);
  };

  return (
    <Form.Group
      className={`mb-3 ${(name !== 'dishesPart' && name !== 'menusPart')
        ? 'd-flex flex-column align-items-center'
        : ''}`}
      controlId="entitySearch"
    >
      <Form.Label>
        {name && descriptions[name]}
        {(name === 'dishesPart' || name === 'menusPart')
        && <span className="text-primary ml-2">*</span>}
      </Form.Label>
      <Form.Control
        type="text"
        value={searchTerm}
        onChange={handleChange}
        list={(name === 'dishesPart' || name === 'menusPart') ? 'suggestions' : ''}
        autoComplete="on"
        className={`${(name !== 'dishesPart' && name !== 'menusPart') ? 'w-30' : ''}`}
        disabled={disabled}
      />
      {(name === 'dishesPart' || name === 'menusPart')
      && searchTerm !== ''
      && searchResults.length > 0
      && (
        <datalist id="suggestions">
          {searchResults
            .filter(
              (entity) => entity.title.trim() !== ''
              && entity.title.toLowerCase() !== searchTerm.toLowerCase(),
            )
            .map((entity) => (
              <option key={entity.id} value={entity.title} />
            ))}
        </datalist>
      )}
    </Form.Group>
  );
}

Searcher.propTypes = {
  name: PropTypes.string.isRequired,
  allEntities: PropTypes.arrayOf(
    PropTypes.shape({
    }),
  ).isRequired,
  setPartEntities: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Searcher.defaultProps = {
  disabled: false,
};

export default Searcher;

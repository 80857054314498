import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import SpinnerWrapper from '../../SpinnerWrapper';
import { useApi } from '../../../../utils/hooks/admin';
import getCurrentDate from '../../../../utils/helpers/FormPart/UserFormPart';

function UserFormPart({
  isAddModal, // Booléen permettant de déterminer le mode (ajout ou édition)
  selectedEntryColumns, // Objet contenant les informations de l'utilisateur en cours d'édition
  setSelectedEntryColumns, // Modifie les informations de l'utilisateur
  associatedEntitiesIds, // Tableau d'id de rôles de l'utilisateur
  setAssociatedEntitiesIds, // Modifie les rôles de l'utilisateur
  allAssociatedEntities, // Tableau de tous les objets rôle
  isLoading,
  handleImageChange,
  handleSubmit,
  handleClose,
}) {
  const { errors } = useApi();

  // Permet d'empêcher la validation du formulaire si aucun rôle n'a été spécifié
  const isAssociatedEntitiesEmpty = associatedEntitiesIds.length === 0;

  // Méthode permettant de déterminer si un rôle est présent parmi ceux de l'utilisateur
  const isAssociatedEntitySelected = (
    associatedEntityId,
  ) => associatedEntitiesIds.includes(associatedEntityId);

  // Méthode permettant de mettre à jour les rôles de l'utilisateur
  const handleAssociatedEntitiesChange = (associatedEntityName) => {
    // Récupère l'ID du rôle sélectionné
    const associatedEntityId = allAssociatedEntities.find(
      (associatedEntity) => associatedEntity.name === associatedEntityName,
    )?.id;

    // Met à jour l'ensemble des rôles de l'utilisateur avec la copie mise à jour
    setAssociatedEntitiesIds(
      (prevAssociatedEntitiesIds) => (prevAssociatedEntitiesIds.includes(associatedEntityId)
      // Si le rôle était déjà présent je le retire sinon je l'ajoute
        ? prevAssociatedEntitiesIds.filter(
          (associatedEntity) => associatedEntity !== associatedEntityId,
        )
        : [...prevAssociatedEntitiesIds, associatedEntityId]
      ),
    );
  };
  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {isAddModal ? 'Édition ' : 'Ajout '}
            d&apos;employé
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>
                Pseudonyme
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, username: e.target.value,
                })}
                value={selectedEntryColumns.username}
                required
              />
              {errors.includes('duplicateUsername')
              && <p className="text-primary">Ce pseudonyme est déjà utilisé.</p>}
              {errors.includes('emptyUsername')
              && <p className="text-primary">Le pseudonyme doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="realname">
              <Form.Label>
                Nom
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, realName: e.target.value,
                })}
                value={selectedEntryColumns.realName}
                required
              />
              {errors.includes('emptyRealName') && (
              <p className="text-primary">
                Le nom doit être spécifié
              </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>
                Mot de passe
                { !isAddModal && <span className="text-primary ml-2">*</span> }
              </Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns,
                  password: e.target.value,
                })}
                value={selectedEntryColumns.password}
                required={!isAddModal}
              />
              {errors.includes('emptyPassword')
              && <p className="text-primary">Le mot de passe doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Rôles à attribuer
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <div>
                {allAssociatedEntities.map((role) => (
                  <Form.Check
                    type="checkbox"
                    key={role.name}
                    label={role.name}
                    name={role.name}
                    id={role.name}
                    value={role.name}
                    // On se sert de notre fct qui vérifie la présence de l'id dans notre Set
                    checked={isAssociatedEntitySelected(role.id)}
                    onChange={() => handleAssociatedEntitiesChange(role.name)}
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>
                Email
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, email: e.target.value,
                })}
                value={selectedEntryColumns.email}
                required
              />
              {errors.includes('emptyEmail')
              && <p className="text-primary">L&apos;e-mail doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="telephone">
              <Form.Label>
                Téléphone
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="tel"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, phoneNumber: e.target.value,
                })}
                value={selectedEntryColumns.phoneNumber}
                required
              />
              {errors.includes('emptyPhoneNumber')
              && <p className="text-primary">Le numéro de téléphone doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="hiredate">
              <Form.Label>Date d&apos;embauche</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns, hireDate: e.target.value,
                })}
                value={selectedEntryColumns.hireDate}
                max={getCurrentDate()}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="enddate">
              <Form.Label>Fin du contrat</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns,
                  endDate: e.target.value,
                })}
                value={selectedEntryColumns.endDate}
                min={getCurrentDate()}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="employmentstatus">
              <Form.Label>
                Type de contrat
              </Form.Label>
              <Form.Select
                className="mb-3"
                aria-label="Type de contrat"
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns,
                  employmentStatus: e.target.value,
                })}
                value={selectedEntryColumns.employmentStatus}
              >
                <option value="" disabled={isAddModal !== null}>-----</option>
                <option value="CDI">CDI</option>
                <option value="CDD">CDD</option>
                <option value="Intérim">Intérim</option>
                <option value="Temps partiel">Temps partiel</option>
                <option value="Saisonnier">Saisonnier</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="socialsecuritynumber">
              <Form.Label>N° Sécu</Form.Label>
              <Form.Control
                type="text"
                maxLength={20}
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns,
                  socialSecurityNumber: e.target.value,
                })}
                value={selectedEntryColumns.socialSecurityNumber}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="comments">
              <Form.Label>Commentaires</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setSelectedEntryColumns({
                  ...selectedEntryColumns,
                  comments: e.target.value,
                })}
                value={selectedEntryColumns.comments}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image de profil</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              <Button variant="success" size="sm" className="me-4" type="submit" disabled={isAssociatedEntitiesEmpty}>
                Valider
              </Button>

              <Button variant="primary" size="sm" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

UserFormPart.propTypes = {
  isAddModal: PropTypes.bool.isRequired,
  selectedEntryColumns: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    realName: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    employmentStatus: PropTypes.string,
    socialSecurityNumber: PropTypes.string,
    comments: PropTypes.string,
    hireDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  setSelectedEntryColumns: PropTypes.func.isRequired,
  associatedEntitiesIds: PropTypes.arrayOf(
    PropTypes.number.isRequired,
  ),
  setAssociatedEntitiesIds: PropTypes.func.isRequired,
  allAssociatedEntities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

UserFormPart.defaultProps = {
  selectedEntryColumns: null,
  associatedEntitiesIds: null,
};

export default UserFormPart;

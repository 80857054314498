import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';

const TeamSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const TeamTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

const Generic = styled.div`
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 2px solid white;
`;

function TeamOptionsFormPart({
  handleSubmit,
  handleImageChange,
  setImageMemberInfos,
  entries,
  setEntries,
}) {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  // Fonction pour gérer le changement de teamMember sélectionné
  const handleTeamMemberChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const selectedMember = entries.teamMembers.find(
      (teamMember) => teamMember.id === selectedId,
    );
    setSelectedTeamMember(selectedMember);
  };

  const handleMemberFieldChange = (value, memberId, fieldName) => {
    // On crée une copie de entries
    const updatedEntries = { ...entries };
    // On trouve le membre correspondant à l'id passé
    const memberToUpdate = updatedEntries.teamMembers.find((member) => member.id === memberId);
    // On met à jour la colonne passée
    if (memberToUpdate) {
      // En modifiant memberToUpdate[fieldName] je modifie updatedEntries.teamMember[fieldName]
      memberToUpdate[fieldName] = value;
    }
    // On met à jour le tout
    setEntries(updatedEntries);
  };

  // Méthode pour gérer le changement d'image pour un champ spécifique
  const handleImageMemberChange = (e, fieldName, memberId) => {
    const file = e.target.files[0];
    // Pour chaque image du formulaire, un objet lui est dédié et il est identifié par son field
    setImageMemberInfos((prevImageMemberInfos) => ({
      ...prevImageMemberInfos,
      [fieldName]: {
        memberId,
        imageFile: file,
        imageName: file.name,
      },
    }));
  };

  return (
    <TeamSection>
      <TeamTitle>Section Team</TeamTitle>
      <Form onSubmit={handleSubmit}>
        <Generic>
          <FormGroup className="mb-3" controlId="flexSwitchCheckChecked">
            <Form.Check
              type="switch"
              id="flexSwitchCheckChecked"
              label="Afficher cette section ?"
              checked={entries.isEnabled}
              onChange={(e) => setEntries({ ...entries, isEnabled: e.target.checked })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamTitle">Titre</FormLabel>
            <FormControl
              type="text"
              id="teamTitle"
              value={entries.title}
              onChange={(e) => setEntries({ ...entries, title: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamDescription">Description</FormLabel>
            <FormControl
              as="textarea"
              rows={5}
              id="teamDescription"
              value={entries.description}
              onChange={(e) => setEntries({ ...entries, description: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamBackgroundImg">Image du background</FormLabel>
            <Form.Control
              type="file"
              id="teamBackgroundImg"
              className="bg-dark"
              onChange={(e) => handleImageChange(e, 'backgroundImg')}
            />
          </FormGroup>
        </Generic>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="teamMembers" className="mt-2">Membres affichés</FormLabel>
          <Form.Select
            id="teamBackgroundImg"
            onChange={handleTeamMemberChange}
            defaultValue=""
          >
            <option value="" disabled>Choisissez un membre</option>
            {entries.teamMembers.map((teamMember, index) => (
              <option key={parseInt(teamMember.id, 10)} value={teamMember.id}>{`Membre ${index + 1}`}</option>
            ))}
          </Form.Select>
        </FormGroup>
        {selectedTeamMember && (
          <>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberName">Nom</FormLabel>
              <FormControl
                type="text"
                id="memberName"
                value={selectedTeamMember.name}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedTeamMember.id, 'name')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberPresentation">Présentation</FormLabel>
              <FormControl
                as="textarea"
                rows={5}
                id="memberPresentation"
                value={selectedTeamMember.presentation}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedTeamMember.id, 'presentation')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberImage">Image du membre</FormLabel>
              <Form.Control
                type="file"
                id="memberImage"
                className="bg-dark"
                onChange={(e) => handleImageMemberChange(e, `imageName_${selectedTeamMember.id}`, selectedTeamMember.id)}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberFbLink">Lien Facebook</FormLabel>
              <FormControl
                type="text"
                id="memberFbLink"
                value={selectedTeamMember.facebookLink}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedTeamMember.id, 'facebookLink')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberTwitterLink">Lien Twitter</FormLabel>
              <FormControl
                type="text"
                id="memberTwitterLink"
                value={selectedTeamMember.twitterLink}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedTeamMember.id, 'twitterLink')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberLinkedinLink">Lien Linkedin</FormLabel>
              <FormControl
                type="text"
                id="memberLinkedinLink"
                value={selectedTeamMember.linkedinLink}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedTeamMember.id, 'linkedinLink')}
              />
            </FormGroup>
          </>
        )}
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </TeamSection>
  );
}

TeamOptionsFormPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  setImageMemberInfos: PropTypes.func.isRequired,
  entries: PropTypes.shape({
    isEnabled: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    teamMembers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        presentation: PropTypes.string,
        facebookLink: PropTypes.string,
        twitterLink: PropTypes.string,
        linkedinLink: PropTypes.string,
      }),
    ),
  }).isRequired,
  setEntries: PropTypes.func.isRequired,
};

export default TeamOptionsFormPart;

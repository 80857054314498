import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';

const FooterSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const FooterTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

const Generic = styled.div`
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 2px solid white;
`;

function FooterOptionsFormPart({
  handleSubmit,
  handleImageChange,
  entries,
  setEntries,
}) {
  return (
    <FooterSection>
      <FooterTitle>Section Footer</FooterTitle>
      <Form onSubmit={handleSubmit}>
        <Generic>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerDescription">A propos</FormLabel>
            <FormControl
              as="textarea"
              rows={5}
              id="footerDescription"
              value={entries.description}
              onChange={(e) => setEntries({ ...entries, description: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerFbLink">Lien Facebook</FormLabel>
            <FormControl
              type="text"
              id="footerFbLink"
              value={entries.facebookLink}
              onChange={(e) => setEntries({ ...entries, facebookLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerTwitterLink">Lien Twitter</FormLabel>
            <FormControl
              type="text"
              id="footerTwitterLink"
              value={entries.twitterLink}
              onChange={(e) => setEntries({ ...entries, twitterLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerGoogleplus">Lien Google+</FormLabel>
            <FormControl
              type="text"
              id="footerGoogleplus"
              value={entries.googleplusLink}
              onChange={(e) => setEntries({ ...entries, googleplusLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerPinterest">Lien Pinterest</FormLabel>
            <FormControl
              type="text"
              id="footerPinterest"
              value={entries.pinterestLink}
              onChange={(e) => setEntries({ ...entries, pinterestLink: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerLinkedinLink">Lien Linkedin</FormLabel>
            <FormControl
              type="text"
              id="footerLinkedinLink"
              value={entries.linkedinLink}
              onChange={(e) => setEntries({ ...entries, linkedinLink: e.target.value })}
            />
          </FormGroup>
        </Generic>
        <Generic>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerAddress">Adresse</FormLabel>
            <FormControl
              type="text"
              id="footerAddress"
              value={entries.address}
              onChange={(e) => setEntries({ ...entries, address: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerPhoneNumber">Téléphone</FormLabel>
            <FormControl
              type="text"
              id="footerPhoneNumber"
              value={entries.phoneNumber}
              onChange={(e) => setEntries({ ...entries, phoneNumber: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerMail">Mail</FormLabel>
            <FormControl
              type="text"
              id="footerMail"
              value={entries.mail}
              onChange={(e) => setEntries({ ...entries, mail: e.target.value })}
            />
          </FormGroup>
        </Generic>
        <Generic>
          <FormLabel htmlFor="footerFirstDays">Jours d&apos;ouverture 1</FormLabel>
          <FormControl
            type="text"
            id="footerFirstDays"
            value={entries.firstOpeningDays}
            onChange={(e) => setEntries({ ...entries, firstOpeningDays: e.target.value })}
          />
          <FormLabel htmlFor="footerFirstHours">Horaires d&apos;ouverture 1</FormLabel>
          <FormControl
            type="text"
            id="footerFirstHours"
            value={entries.firstOpeningHours}
            onChange={(e) => setEntries({ ...entries, firstOpeningHours: e.target.value })}
          />
          <FormLabel htmlFor="footerSecondDays">Jours d&apos;ouverture 2</FormLabel>
          <FormControl
            type="text"
            id="footerSecondDays"
            value={entries.secondaryOpeningDays}
            onChange={(e) => setEntries({ ...entries, secondaryOpeningDays: e.target.value })}
          />
          <FormLabel htmlFor="footerSecondHours">Horaires d&apos;ouverture 2</FormLabel>
          <FormControl
            type="text"
            id="footerSecondHours"
            value={entries.secondaryOpeningHours}
            onChange={(e) => setEntries({ ...entries, secondaryOpeningHours: e.target.value })}
          />
        </Generic>
        <Generic>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="footerBackgroundImg">Image du background</FormLabel>
            <Form.Control
              type="file"
              id="footerBackgroundImg"
              className="bg-dark"
              onChange={(e) => handleImageChange(e, 'backgroundImg')}
            />
          </FormGroup>
        </Generic>
        <Generic>
          <FormLabel htmlFor="footerCopyright">Copyright</FormLabel>
          <FormControl
            type="text"
            id="footerCopyright"
            value={entries.copyright}
            onChange={(e) => setEntries({ ...entries, copyright: e.target.value })}
          />
        </Generic>
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </FooterSection>
  );
}

FooterOptionsFormPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  entries: PropTypes.shape({
    description: PropTypes.string,
    facebookLink: PropTypes.string,
    twitterLink: PropTypes.string,
    googleplusLink: PropTypes.string,
    pinterestLink: PropTypes.string,
    linkedinLink: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    mail: PropTypes.string,
    firstOpeningDays: PropTypes.string,
    firstOpeningHours: PropTypes.string,
    secondaryOpeningDays: PropTypes.string,
    secondaryOpeningHours: PropTypes.string,
    copyright: PropTypes.string,
  }).isRequired,
  setEntries: PropTypes.func.isRequired,
};

export default FooterOptionsFormPart;

/* eslint-disable no-unused-vars */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const OrderCell = styled.td`
  vertical-align: middle !important;
`;

const OrderProductContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const OrderProductImgContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-right: 24px;
`;

const OrderProductImg = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const OrderProductInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderRemove = styled.td`
  vertical-align: middle !important;
`;

const OrderCloseButton = styled.button`
  background-color: #202020;
  border: none;
`;

function MenuOrderDetails({
  order,
  setOrders,
}) {
  // Permet la suppression d'un produit du panier
  const handleRemoveProduct = (productId) => {
    // On retire le produit qui correspond aux conditions
    setOrders((prevOrders) => prevOrders.filter((prevOrder) => (
      !(prevOrder.id === productId)
    )));
  };
  return (
    <tr>
      <OrderCell className="p-4">
        <OrderProductContent>
          <OrderProductImgContainer>
            <OrderProductImg src={`https://ti-pei-gourmand.fr/images/menus/${order.imageName}`} alt="" />
          </OrderProductImgContainer>
          <OrderProductInfos>
            <p>
              Menu
              {' '}
              {order.title}
            </p>
            <p>
              <small style={{ textDecoration: 'underline' }}>
                Plats choisis
              </small>
              <small>
                {' '}
                :
              </small>
            </p>
            {order.associatedDishes.map((associatedDish) => (
              <small key={associatedDish.id}>
                <span>
                  -
                  {' '}
                  {associatedDish.title}
                  {' '}
                  {associatedDish.ingredientsToRemove.length > 0 && (
                    <span className="text-muted">
                      {/* Impossible de faire un espace classique au début de span */}
                      &nbsp;
                      {'--->'}
                      {' '}
                      Demande(s):
                      {' '}
                      Retrait
                      {' '}
                      {associatedDish.ingredientsToRemove.map(
                        (ingredientToRemove, ingredientToRemoveIndex) => (
                          <span key={ingredientToRemove}>
                            {ingredientToRemove}
                            {ingredientToRemoveIndex < associatedDish.ingredientsToRemove.length - 1 && ', '}
                          </span>
                        ),
                      )}
                    </span>
                  )}
                  {associatedDish.selectedMeat !== '' && (
                    <span className="text-muted">
                      {/* Impossible d'utiliser ' ', ni &nbsp. Donc '\u00A0' fonctionne */}
                      {associatedDish.ingredientsToRemove.length === 0 ? '\u00A0' : ''}
                      {associatedDish.ingredientsToRemove.length === 0 ? '--->' : ''}
                      {associatedDish.ingredientsToRemove.length === 0 ? ' ' : ' / '}
                      Cuisson:
                      {' '}
                      {associatedDish.selectedMeat}
                    </span>
                  )}
                </span>
              </small>
            ))}
          </OrderProductInfos>
        </OrderProductContent>
      </OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price}
        {' '}
        €
      </OrderCell>
      <OrderCell className="align-middle p-4 text-center">1</OrderCell>
      <OrderCell className="text-center font-weight-semibold align-middle p-4">
        {order.price * order.quantity}
        {' '}
        €
      </OrderCell>
      <OrderRemove className="text-center align-middle px-0"><OrderCloseButton type="button" style={{ color: 'red' }} onClick={() => handleRemoveProduct(order.id)}>x</OrderCloseButton></OrderRemove>
    </tr>
  );
}

MenuOrderDetails.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    imageName: PropTypes.string,
    title: PropTypes.string,
    associatedDishes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imageName: PropTypes.string,
        title: PropTypes.string,
        ingredientsToRemove: PropTypes.arrayOf(
          PropTypes.string,
        ),
        selectedMeat: PropTypes.string,
        price: PropTypes.number,
        quantity: PropTypes.number,
      }),
    ),
    price: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
  setOrders: PropTypes.func.isRequired,
};

export default MenuOrderDetails;

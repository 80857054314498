/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import MenuPresentation from '../MenuPresentation';
import DishDetails from '../DishDetails';

const ProductCarousel = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 999;
  margin: 0px;
  padding: 0px;
`;

function MenuDetails({
  menu,
  orders,
  setOrders,
  setMenuDetails,
  tableNumber,
}) {
  // Permet d'obtenir les différentes catégorie des plats du menu
  const uniqueCategories = [...new Set(menu.menuDishes.map((menuDish) => menuDish.dish.category))];

  const [carouselIndex, setCarouselIndex] = useState(0);

  // On récupère les propriété de menu si il est passé en prop
  const {
    title,
    price,
    imageName,
  } = menu || {};

  const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;

  // Contient les informations pertinentes du menu sélectionné
  const [menuOrderInfos, setMenuOrderInfos] = useState(
    {
      id: generateUniqueId(),
      title,
      price,
      imageName,
      quantity: 1,
      associatedDishes: [],
    },
  );

  // Permet d'obtenir un tableau de plat pour chaque catégorie
  const dishesByCategories = uniqueCategories.map((uniqueCategory) => (
    menu.menuDishes.filter((menuDish) => menuDish.dish.category === uniqueCategory)
  ));

  return (
    <ProductCarousel className="container">
      <MenuPresentation
        menu={menu}
        uniqueCategories={uniqueCategories}
        carouselIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
        setDishDetails={setMenuDetails}
        tableNumber={tableNumber}
      />
      {dishesByCategories.map((dishesByCategory) => (
        <DishDetails
          key={dishesByCategory[0].id}
          dish={dishesByCategory}
          orders={orders}
          setOrders={setOrders}
          setDishDetails={setMenuDetails}
          tableNumber={tableNumber}
          carouselIndex={carouselIndex}
          setCarouselIndex={setCarouselIndex}
          isFromMenu
          menuOrderInfos={menuOrderInfos}
          setMenuOrderInfos={setMenuOrderInfos}
        />
      ))}
    </ProductCarousel>
  );
}

MenuDetails.propTypes = {
  menu: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    imageName: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.number,
    menuDishes: PropTypes.arrayOf(
      PropTypes.shape({

      }),
    ),
  }).isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
  setOrders: PropTypes.func.isRequired,
  setMenuDetails: PropTypes.func.isRequired,
  tableNumber: PropTypes.number.isRequired,
};

export default MenuDetails;

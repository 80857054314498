/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const ConfirmationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: #202020;
  color: white !important;
  text-align: center;
  z-index: 999;
  padding: 50px;
`;

const ConfirmationTitle = styled.h2`
  color: white !important;
`;

function Error({ errorMessage }) {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          clearInterval(interval);
          window.location.href = '/restaurant';
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);
  return (
    <ConfirmationContainer>
      <ConfirmationTitle>
        {errorMessage}
        <br />
        <br />
        Redirection dans :
        {' '}
        {counter}
        {' '}
        s
      </ConfirmationTitle>
    </ConfirmationContainer>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default Error;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import SpinnerWrapper from '../../SpinnerWrapper';
import getCurrentDate from '../../../../utils/helpers/FormPart/UserFormPart';
import { useApi } from '../../../../utils/hooks/admin';

const WorkingEmployees = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin-bottom: 30px;
`;

const WorkingEmployeesOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const WorkingEmployeesEmail = styled.div`
  display: flex;
  justify-content: center;
`;

function PlanningFormPart({
  selectedEntryColumns, // Objet contenant les informations de l'ingrédient en cours d'édition
  setSelectedEntryColumns, // Modifie les informations de l'ingrédient
  handleSubmit,
  handleClose,
}) {
  const { authToken, fetchData } = useApi();

  // Contient le nom de tous les employés
  const [employees, setEmployees] = useState([]);

  // Contient la date de la plage horaire
  const [selectedDate, setSelectedDate] = useState('');

  // Contient l'heure de début de la tranche horaire
  const [selectedStartTime, setSelectedStartTime] = useState('');

  // Contient l'heure de fin de la tranche horaire
  const [selectedEndTime, setSelectedEndTime] = useState('');

  // Contient le nom de l'employé actuellement sélectionné
  const [selectedEmployee, setSelectedEmployee] = useState('');

  // Contient la liste des rôles attribuables à un employé
  // eslint-disable-next-line no-unused-vars
  const roles = ['Serveur', 'Cuisinier', 'Caissier', 'Plongeur', 'Réceptionniste', 'Sécurité'];

  // Contient le rôle actuellement sélectionné
  const [selectedRole, setSelectedRole] = useState('');

  // Contient les informations de tous les employés affectés à la plage horaire
  // eslint-disable-next-line no-unused-vars
  const [workingEmployees, setWorkingEmployees] = useState([]);

  // Contient toutes les possibilités de répétition de tranche pour un employé
  const repetitions = ['Non', 'Pendant 1 mois', 'Pendant 3 mois', 'Pendant 1 année'];

  // Contient la repetition actuellement sélectionnée
  const [selectedRepetitions, setSelectedRepetitions] = useState({});

  // Permet de gérer l'affichage de la checkbox d'envoi de mails
  const [sendEmail, setSendEmail] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour formater la date et l'heure en chaîne `YYYY-MM-DD HH:MM:SS`
  const formatDateTime = (date, time) => `${date} ${time}`;

  // Permet d'ajouter un employé à la liste de ceux concernés par la tranche horaire
  const handleAddEmployee = () => {
    const startTime = formatDateTime(selectedDate, selectedStartTime);
    const endTime = formatDateTime(selectedDate, selectedEndTime);

    const newEmployee = {
      userId: selectedEmployee,
      startTime,
      endTime,
      activity: selectedRole,
    };

    // Retrouver l'employé correspondant à l'id (userId) dans le state employees
    const selectedEmployeeData = employees.find((employee) => employee.id === selectedEmployee);

    if (selectedEmployeeData) {
      newEmployee.username = selectedEmployeeData.username;
    }

    setWorkingEmployees((prevWorkingEmployees) => [...prevWorkingEmployees, newEmployee]);

    setEmployees((prevEmployees) => prevEmployees.filter(
      (employee) => employee.id !== selectedEmployee,
    ));

    setSelectedEmployee('');
    setSelectedRole('');
  };

  // Permet de retirer un employé de la liste de ceux concernés par la tranche horaire
  const removeEmployee = (userId, username) => {
    setWorkingEmployees((prevWorkingEmployees) => prevWorkingEmployees.filter(
      (prevWorkingEmployee) => prevWorkingEmployee.userId !== userId,
    ));

    // Supprime la répétition associée à cet employé
    setSelectedRepetitions((prevSelectedRepetitions) => {
      // On prend notre objet contenant {255: 'Pendant 1 mois', 254: ..., }
      // On destructure et on retire la partie dont l'id est trouvé
      const { [userId]: _, ...rest } = prevSelectedRepetitions;
      return rest;
    });

    // Si on retire l'employé de la tranche horaire, on le rajoute dans la liste de propositions
    setEmployees((prevEmployees) => [...prevEmployees, { id: userId, username }]);
  };

  // Fonction pour gérer les changements de répétition
  const handleRepetitionChange = (userId, value) => {
    setSelectedRepetitions((prevSelectedRepetitions) => ({
      ...prevSelectedRepetitions,
      [userId]: value,
    }));
  };

  const handleSubsubmit = (e) => {
    e.preventDefault();

    // Fonction pour ajouter des jours tout en conservant l'heure d'origine
    const addDaysToDateTime = (dateTime, daysToAdd) => {
      // Diviser la chaîne en date et heure
      const [dateString, timeString] = dateTime.split(' ');

      // Diviser la chaîne de temps en heures et minutes, avec une gestion des secondes potentielles
      const timeParts = timeString.split(':');
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);
      const seconds = timeParts[2] ? Number(timeParts[2]) : 0; // Gérer les secondes s'il y en a

      // Diviser la chaîne de date en année, mois et jour
      const [year, month, day] = dateString.split('-').map(Number);

      // Créer un objet Date en utilisant les valeurs extraites
      const date = new Date(year, month - 1, day, hours, minutes, seconds);

      // Ajouter des jours à la date
      date.setDate(date.getDate() + daysToAdd);

      // Formater la date au format 'YYYY-MM-DD HH:MM:SS'
      const yearOut = date.getFullYear();
      const monthOut = String(date.getMonth() + 1).padStart(2, '0');
      const dayOut = String(date.getDate()).padStart(2, '0');
      const hoursOut = String(date.getHours()).padStart(2, '0');
      const minutesOut = String(date.getMinutes()).padStart(2, '0');
      const secondsOut = String(date.getSeconds()).padStart(2, '0');

      return `${yearOut}-${monthOut}-${dayOut} ${hoursOut}:${minutesOut}:${secondsOut}`;
    };

    // On récupère la date et l'heure de la tranche horaire
    const baseStartDateTime = `${selectedDate} ${selectedStartTime}`;
    const baseEndDateTime = `${selectedDate} ${selectedEndTime}`;

    // On crée une copie de `workingEmployees` sans le `username`
    let updatedWorkingEmployees = workingEmployees.map(({ username, ...rest }) => rest);

    // On parcourt tous les employés avec une répétition spécifiée
    updatedWorkingEmployees = updatedWorkingEmployees.flatMap((employee) => {
      const repetition = selectedRepetitions[employee.userId];
      const newEntries = [];

      // Ajout de la tranche horaire initiale
      newEntries.push({
        ...employee,
        startTime: baseStartDateTime,
        endTime: baseEndDateTime,
      });

      if (repetition === 'Pendant 1 mois') {
        // Ajouter des tranches toutes les semaines pendant 1 mois
        for (let i = 1; i <= 4; i += 1) {
          const newStartTime = addDaysToDateTime(baseStartDateTime, i * 7);
          const newEndTime = addDaysToDateTime(baseEndDateTime, i * 7);

          newEntries.push({
            ...employee,
            startTime: newStartTime,
            endTime: newEndTime,
          });
        }
      } else if (repetition === 'Pendant 3 mois') {
        // Ajouter des tranches toutes les semaines pendant 3 mois
        for (let i = 1; i <= 12; i += 1) {
          const newStartTime = addDaysToDateTime(baseStartDateTime, i * 7);
          const newEndTime = addDaysToDateTime(baseEndDateTime, i * 7);

          newEntries.push({
            ...employee,
            startTime: newStartTime,
            endTime: newEndTime,
          });
        }
      } else if (repetition === 'Pendant 1 année') {
        // Ajouter des tranches toutes les semaines pendant 1 an
        for (let i = 1; i <= 52; i += 1) {
          const newStartTime = addDaysToDateTime(baseStartDateTime, i * 7);
          const newEndTime = addDaysToDateTime(baseEndDateTime, i * 7);

          newEntries.push({
            ...employee,
            startTime: newStartTime,
            endTime: newEndTime,
          });
        }
      }

      return newEntries;
    });

    // Mise à jour des colonnes sélectionnées avec les tranches horaires recalculées
    setSelectedEntryColumns({
      workingEmployees: updatedWorkingEmployees,
      sendEmail,
    });
  };

  useEffect(() => {
    // Méthode permettant la récupération de la liste des entités
    const fechDataAsync = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      // Requête l'API récupérant la liste des entités et construisant le cache de ces derniers
      const { data } = await fetchData('https://ti-pei-gourmand.fr/api/users/username', options);

      // Si aucune donnée n'est retournée
      if (!data) {
        // On retire le loading
        setIsLoading(false);

        // On ne fait rien de plus et on sort de la méthode
        return;
      }

      // Je récupère toutes les informations pertinentes concernant mes entrées
      setEmployees(data['hydra:member'].map((entry) => ({
        id: entry.id,
        username: entry.username,
      })));

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    };

    fechDataAsync();
  }, []);

  useEffect(() => {
    if (selectedEntryColumns.workingEmployees && selectedEntryColumns.workingEmployees.length > 0) {
      // Soumission du formulaire
      handleSubmit();
    }
  }, [selectedEntryColumns]);

  console.log('workingEmployees : ', workingEmployees);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Attribution d&apos;horaires de travail
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubsubmit}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="startTime">
              <Form.Label>Date de la tranche horaire</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setSelectedDate(
                  e.target.value,
                )}
                value={selectedDate}
                min={getCurrentDate()}
              />
            </Form.Group>

            {
              selectedDate && (
                <Form.Group className="mb-3" controlId="startTime">
                  <Form.Label>Début de la tranche horaire</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) => setSelectedStartTime(
                      e.target.value,
                    )}
                    value={selectedEntryColumns.startTime}
                  />
                </Form.Group>
              )
            }

            {
              selectedStartTime && (
                <Form.Group className="mb-3" controlId="endTime">
                  <Form.Label>Fin de la tranche horaire</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) => setSelectedEndTime(
                      e.target.value,
                    )}
                    value={selectedEntryColumns.endTime}
                  />
                </Form.Group>
              )
            }

            {
              selectedEndTime && (
                <Form.Group className="mb-3" controlId="employees">
                  <Form.Label>
                    Employés
                  </Form.Label>
                  <Form.Select
                    name="employees"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(parseInt(e.target.value, 10))}
                  >
                    <option value="" disabled>Sélectionner un employé</option>
                    {employees.map((employee) => (
                      <option
                        key={employee.username}
                        value={employee.id}
                      >
                        {employee.username}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )
            }

            {
              selectedEmployee && (
                <Form.Group className="mb-3" controlId="roles">
                  <Form.Label>
                    Rôle
                  </Form.Label>
                  <Form.Select
                    name="roles"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="" disabled>Sélectionner un rôle</option>
                    {roles.map((role) => (
                      <option key={role} value={role}>{role}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )
            }

            {
              selectedRole && (
                <div className="d-flex justify-content-center w-100 mb-4">
                  <Button
                    variant="success"
                    size="sm"
                    className="me-4"
                    onClick={handleAddEmployee}
                  >
                    Ajouter
                  </Button>
                </div>
              )
            }

            {
              workingEmployees.length > 0 && (
                <div className="w-100 mb-4 text-center">
                  {workingEmployees.map((workingEmployee) => (
                    <WorkingEmployees key={workingEmployee.userId}>
                      <WorkingEmployeesOptions>
                        {workingEmployee.username}
                        {' '}
                        --&gt;
                        {' '}
                        {workingEmployee.activity}
                        <Form.Select
                          name="repetition"
                          value={selectedRepetitions[workingEmployee.userId] || ''}
                          onChange={
                            (e) => handleRepetitionChange(workingEmployee.userId, e.target.value)
                          }
                        >
                          <option value="" disabled>Répéter la tranche ?</option>
                          {repetitions.map((repetition) => (
                            <option key={repetition} value={repetition}>{repetition}</option>
                          ))}
                        </Form.Select>
                      </WorkingEmployeesOptions>
                      <Button
                        variant="warning"
                        size="sm"
                        className="ms-4"
                        onClick={
                          () => removeEmployee(workingEmployee.userId, workingEmployee.username)
                        }
                      >
                        Retirer
                      </Button>
                    </WorkingEmployees>
                  ))}
                  <WorkingEmployeesEmail>
                    <Form.Group controlId="sendEmail">
                      <Form.Check
                        type="checkbox"
                        label="Avertir par email"
                        checked={sendEmail}
                        onChange={(e) => setSendEmail(e.target.checked)}
                      />
                    </Form.Group>
                  </WorkingEmployeesEmail>
                </div>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-center w-100">
              <Button variant="success" size="sm" className="me-4" type="submit">
                Valider
              </Button>

              <Button variant="primary" size="sm" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

PlanningFormPart.propTypes = {
  selectedEntryColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.number,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        username: PropTypes.string,
        activity: PropTypes.string,
      }),
    ),
    PropTypes.shape({
    }),
  ]).isRequired,
  setSelectedEntryColumns: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PlanningFormPart;

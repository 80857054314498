/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
  LineChart,
  Line,
} from 'recharts';
import styled from 'styled-components';
import { useApi } from '../../../utils/hooks/admin';
import SpinnerWrapper from '../SpinnerWrapper';

const OrdersGraph = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
`;

function OrdersByDayChart() {
  const { authToken, fetchData } = useApi();

  // State contenant la période sélectionnée
  const [selectedPeriod, setSelectedPeriod] = useState('week');

  // State contenant toutes les commandes
  const [ordersData, setOrdersData] = useState([]);

  // State contenant les données filtrées par période
  const [filteredData, setFilteredData] = useState([]);

  // State contenant le nombre total de commandes
  const [totalOrders, setTotalOrders] = useState(0);

  // State contentant les revenus totaux
  const [totalProfit, setTotalProfit] = useState(0);

  // State contenant les objectifs à atteindre (de nb commandes, de revenus etc...)
  const [targetOrders, setTargetOrders] = useState({});

  // State contenant les commandes filtrées par période et classées par catégorie de plat
  const [transformedDishesDataByTime, setTransformedDishesDataByTime] = useState({
    Entrée: [],
    'Plat principal': [],
    Accompagnement: [],
    Dessert: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour formater la valeur dans la tooltip
  const formatTooltipValue = (value, name) => {
    if (name === 'Revenus' || name === 'Recettes') {
      return `${value} €`;
    }
    return value;
  };

  // Fonction pour trier les plats par revenue
  const sortByRevenue = (data) => {
    // Parcourir chaque catégorie de l'objet
    Object.keys(data).forEach((category) => {
      data[category].sort((a, b) => b.revenue - a.revenue);
    });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const dataOptions = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };
      const { data: restaurantData } = await fetchData('https://ti-pei-gourmand.fr/api/restaurant_options', dataOptions);

      setTargetOrders(restaurantData['hydra:member'][0]);

      const { data: allOrdersData } = await fetchData('https://ti-pei-gourmand.fr/api/ordersYear', dataOptions);

      setOrdersData(allOrdersData);

      setIsLoading(false);
    };

    if (authToken) {
      fetchOptions();
    }
  }, [authToken]);

  // Permet de ne récupérer que les commandes de la tranche sélectionnée
  useEffect(() => {
    const filterData = () => {
      // Je récupère la date actuelle
      const today = new Date();

      // Je jour change après la dernière seconde de la dernière heure
      today.setHours(23, 59, 59, 999);

      let filtered;

      switch (selectedPeriod) {
        case 'week': {
          // Je crée une nouvelle date basée sur la date actuelle
          const startOfWeek = new Date(today);

          // Je calcule le jour de la semaine (0 pour dimanche, 1 pour lundi)
          const dayOfWeek = today.getDay();

          // Je retranche le jour de la semaine à la date actuelle pour obtenir celle du lundi
          if (dayOfWeek === 0) {
            // Si le jour de la semaine est dimanche (0), revenir de 6 jours pour obtenir lundi
            startOfWeek.setDate(today.getDate() - 6);
          } else {
            // Sinon, revenir de (dayOfWeek - 1) jours pour obtenir lundi
            startOfWeek.setDate(today.getDate() - (dayOfWeek - 1));
          }

          // Je règle l'heure de startOfWeek à minuit (début de la journée)
          startOfWeek.setHours(0, 0, 0, 0);

          // Filtrer les données pour obtenir les commandes de la semaine actuelle
          filtered = ordersData.filter((order) => {
            const orderDate = new Date(order.date);
            return orderDate >= startOfWeek && orderDate <= today;
          });

          break;
        }
        case 'month': {
          // Je crée une date correspondant au début du mois actuel
          const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          filtered = ordersData.filter((order) => {
            const orderDate = new Date(order.date);
            return orderDate >= startOfMonth && orderDate <= today;
          });
          break;
        }
        case 'year': {
          // Je crée une date correspondant au début de l'année actuelle
          const startOfYear = new Date(today.getFullYear(), 0, 1);
          filtered = ordersData.filter((order) => {
            const orderDate = new Date(order.date);
            return orderDate >= startOfYear && orderDate <= today;
          });

          // Je groupe par mois dans le cas de l'année en parcourant mes commandes
          const groupedByMonth = filtered.reduce((acc, order) => {
            // Je récupère le mois de la commande
            const month = new Date(order.date).getMonth();

            // Si l'entrée pour ce mois n'existe pas encore
            if (!acc[month]) {
              // Je la crée
              acc[month] = {
                month,
                commandes: 0,
                recette: 0,
                platsCommandes: [],
              };
            }

            // J'incrémente le nombre de commandes et les recettes pour l'entrée
            acc[month].commandes += order.commandes;
            acc[month].recette += order.recette;

            // Limiter le nombre de chiffres après la virgule à 2 et évite le bug des float
            acc[month].recette = Math.round(acc[month].recette * 100) / 100;

            if (!acc[month].platsCommandes) {
              acc[month].platsCommandes = [];
            }

            acc[month].platsCommandes.push(...order.platsCommandes);

            return acc;
          }, {});

          // J'en crée un tableau d'objets
          filtered = Object.keys(groupedByMonth).map((month) => ({
            date: new Date(today.getFullYear(), month, 1).toLocaleString('default', { month: 'long' }),
            commandes: groupedByMonth[month].commandes,
            recette: groupedByMonth[month].recette,
            platsCommandes: groupedByMonth[month].platsCommandes,
          }));
          break;
        }
        default:
          filtered = ordersData;
      }

      setFilteredData(filtered);

      // Calculer le total des commandes
      const total = filtered.reduce((acc, order) => acc + order.commandes, 0);
      setTotalOrders(total);

      // Calculer les gains totaux
      const totalProf = filtered.reduce((acc, order) => acc + order.recette, 0);
      setTotalProfit(Math.round(totalProf * 100) / 100);
    };
    if (ordersData.length > 0) {
      filterData();
    }
  }, [selectedPeriod, ordersData]);

  // Quand les commandes sont filtrées par tranche de date
  useEffect(() => {
    const filterDishDate = () => {
      // Je crée un objet qui contiendra les plats classés par catégorie
      const transformedDishesData = {
        Entrée: [],
        'Plat principal': [],
        Accompagnement: [],
        Dessert: [],
      };

      // Je parcours les commandes de cette tranche
      filteredData.forEach((order) => {
        // Je m'intéresse aux plats des commandes
        order.platsCommandes.forEach((plat) => {
          // Je récupère la catégorie des plats
          const { category } = plat;

          // Générer un identifiant unique pour le plat
          const platIdentifier = `${plat.plat}_${plat.id}`; // Exemple : "NomPlat_123"

          // Je vérifie si le plat a déjà été ajouté à la catégorie
          const existingEntry = transformedDishesData[category].find((entry) => entry.platIdentifier === platIdentifier);

          // Si oui on incrémente le nombre de ce plat et ses revenus générés
          if (existingEntry) {
            existingEntry.quantite += plat.quantite;
            existingEntry.revenue += plat.revenue;

            // Limiter le nombre de chiffres après la virgule à 2 et évite le bug des float
            existingEntry.revenue = Math.round(existingEntry.revenue * 100) / 100;

          // Sinon on ajoute ce plat à la catégorie
          } else {
            transformedDishesData[category].push({
              platIdentifier,
              plat: plat.plat.length > 15 ? `${plat.plat.substring(0, 15)}...` : plat.plat,
              quantite: plat.quantite,
              revenue: parseFloat(plat.revenue.toFixed(2)),
            });
          }
        });
      });

      // Trier les plats par revenue
      sortByRevenue(transformedDishesData);

      setTransformedDishesDataByTime(transformedDishesData);
    };

    if (filteredData.length > 0) {
      filterDishDate();
    }
  }, [filteredData]);

  console.log('transformedDishesDataByTime : ', transformedDishesDataByTime);
  console.log('filteredDish : ', filteredData);
  console.log(targetOrders.totalMonthOrderAim);

  return (
    <div>
      <SpinnerWrapper $showSpinner={isLoading} />
      <OrdersGraph>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={filteredData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={formatTooltipValue} />
            <Bar dataKey="commandes" fill="#8884d8" />
            <ReferenceLine y={selectedPeriod !== 'year' ? targetOrders.totalOrderAim : targetOrders.totalMonthOrderAim} stroke="red" strokeDasharray="3 3" />
            <Legend
              payload={[
                {
                  value: 'Objectif visé',
                  type: 'line',
                  id: 'totalOrderAim',
                  color: 'red',
                  strokeDasharray: '3 3',
                },
              ]}
            />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Nombre de commandes :
          {' '}
          {totalOrders}
        </div>
      </OrdersGraph>

      <OrdersGraph>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={filteredData}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis domain={[0, (dataMax) => Math.ceil(dataMax + dataMax * 0.1)]} />
            <Tooltip formatter={formatTooltipValue} />
            <ReferenceLine y={selectedPeriod !== 'year' ? targetOrders.profitAim : targetOrders.monthProfitAim} stroke="red" strokeDasharray="3 3" />
            <Legend
              payload={[
                {
                  value: 'Objectif visé',
                  type: 'line',
                  color: 'red',
                  strokeDasharray: '3 3',
                },
              ]}
            />
            <Line type="monotone" dataKey="recette" stroke="#8884d8" name="Recettes" />
          </LineChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Revenus totaux :
          {' '}
          {totalProfit}
          {' €'}
        </div>
      </OrdersGraph>

      <OrdersGraph>
        <ResponsiveContainer width="100%" height={550}>
          <BarChart
            data={transformedDishesDataByTime['Entrée']}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="plat"
              angle={-90}
              textAnchor="end"
              interval={0}
              height={150}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" domain={[0, (dataMax) => Math.ceil(dataMax + dataMax * 0.1)]} />
            <Tooltip formatter={formatTooltipValue} />
            <Legend />
            <Bar yAxisId="left" dataKey="quantite" fill="#8884d8" name="Quantité" />
            <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Revenus" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Popularité et Revenus des entrées
        </div>
      </OrdersGraph>

      <OrdersGraph>
        <ResponsiveContainer width="100%" height={550}>
          <BarChart
            data={transformedDishesDataByTime['Plat principal']}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="plat"
              angle={-90}
              textAnchor="end"
              interval={0}
              height={150}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="#82ca9d"
              domain={[0, (dataMax) => Math.ceil(dataMax + dataMax * 0.1)]}
            />
            <Tooltip formatter={formatTooltipValue} />
            <Legend />
            <Bar yAxisId="left" dataKey="quantite" fill="#8884d8" name="Quantité" />
            <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Revenus" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Popularité et Revenus des plats principaux
        </div>
      </OrdersGraph>

      <OrdersGraph>
        <ResponsiveContainer width="100%" height={550}>
          <BarChart
            data={transformedDishesDataByTime.Accompagnement}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="plat"
              angle={-90}
              textAnchor="end"
              interval={0}
              height={150}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" domain={[0, (dataMax) => Math.ceil(dataMax + dataMax * 0.1)]} />
            <Tooltip formatter={formatTooltipValue} />
            <Legend />
            <Bar yAxisId="left" dataKey="quantite" fill="#8884d8" name="Quantité" />
            <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Revenus" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Popularité et Revenus des accompagnements
        </div>
      </OrdersGraph>

      <OrdersGraph>
        <ResponsiveContainer width="100%" height={550}>
          <BarChart
            data={transformedDishesDataByTime.Dessert}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="plat"
              angle={-90}
              textAnchor="end"
              interval={0}
              height={150}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" domain={[0, (dataMax) => Math.ceil(dataMax + dataMax * 0.1)]} />
            <Tooltip formatter={formatTooltipValue} />
            <Legend />
            <Bar yAxisId="left" dataKey="quantite" fill="#8884d8" name="Quantité" />
            <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Revenus" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center' }}>
          Popularité et Revenus des desserts
        </div>
      </OrdersGraph>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <label>
          Commandes effectuées
          {' '}
          {selectedPeriod === 'week' || selectedPeriod === 'year' ? 'cette' : 'ce'}
          <select
            onChange={(e) => setSelectedPeriod(e.target.value)}
            value={selectedPeriod}
            style={{ marginLeft: '5px' }}
          >
            <option value="week">semaine</option>
            <option value="month">mois</option>
            <option value="year">année</option>
          </select>
        </label>
      </div>
    </div>
  );
}

export default OrdersByDayChart;

import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import SpinnerWrapper from '../../SpinnerWrapper';
import { useApi } from '../../../../utils/hooks/admin';
import Searcher from '../../Searcher';
import { dishCategoryChoice, getEntityProperty, getModalTitle } from '../../../../utils/helpers/FormPart/DishFormPart';

function MenuFormPart({
  isAddModal, // Booléen permettant de déterminer le mode (ajout ou édition)
  associatedEntitiesIds, // Tableau d'ids des plats du menu
  setAssociatedEntitiesIds, // Modifie les plats du menu
  allAssociatedEntities, // Tableau de tous les objets Plat
  selectedEntryColumns, // Objet contenant les informations du menu
  setSelectedEntryColumns, // Modifie les informations du menu
  isLoading,
  handleImageChange,
  handleSubmit,
  handleClose,
}) {
  const { errors, authPermissions } = useApi();

  // State contenant l'objet représentant le plat trouvé via l'input de recherche
  const [selectedDish, setSelectedDish] = useState(null);

  // State contenant le terme de la recherche de l'input de recherche
  const [searchTerm, setSearchTerm] = useState('');

  // Détermine en fonction des permissions si mes inputs doivent être disabled ou non
  const isInputDisabled = !authPermissions.includes('Mise à jour des menus');

  // Permet d'empêcher la validation du formulaire si aucun ingrédient n'a été spécifié
  const isAssociatedEntitiesEmpty = associatedEntitiesIds.length === 0;

  // Aide à définir le titre de la modale
  const modalTitle = getModalTitle(isAddModal, isInputDisabled);

  // Méthode ajoutant un plat au menu s'il n'en fait pas encore partie
  const handleAddDish = (dish) => {
    const existingDishIndex = associatedEntitiesIds.findIndex(
      (item) => item.id === dish.id,
    );
    if (existingDishIndex === -1) {
      setAssociatedEntitiesIds([
        ...associatedEntitiesIds,
        dish.id,
      ]);
    }

    // Je reset mes states de recherche
    setSelectedDish(null);
    setSearchTerm('');
  };

  // Méthode permettant de retirer un plat au menu
  const handleRemoveDish = (dishId) => {
    const updatedSelectedDishes = associatedEntitiesIds.filter(
      (dish) => dish !== dishId,
    );
    setAssociatedEntitiesIds(updatedSelectedDishes);
  };

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {modalTitle}
            de menu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>
                Nom
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSelectedEntryColumns(
                  {
                    ...selectedEntryColumns,
                    title: e.target.value,
                  },
                )}
                value={selectedEntryColumns.title}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('duplicateTitle')
              && <p className="text-primary">Ce menu est déjà enregistré.</p>}
              {errors.includes('emptyTitle')
              && <p className="text-primary">Le nom du menu doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>
                Description
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Courte présentation du menu..."
                onChange={
                  (e) => setSelectedEntryColumns(
                    { ...selectedEntryColumns, description: e.target.value },
                  )
                }
                value={selectedEntryColumns.description}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyDescription') && (
              <p className="text-primary">
                La description du menu doit être spécifiée
              </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Prix (en €)
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                onChange={(e) => {
                  const { value } = e.target;
                  const price = parseFloat(value);
                  setSelectedEntryColumns((prevEntryColumns) => ({
                    ...prevEntryColumns,
                    price: Number.isNaN(price) ? prevEntryColumns.price : price,
                  }));
                }}
                value={selectedEntryColumns.price}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyPrice')
              && <p className="text-primary">Le prix doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Vendre ce menu ?
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={(event) => {
                  const { checked } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    isSaled: checked,
                  }));
                }}
                checked={selectedEntryColumns.isSaled}
              />
            </Form.Group>

            <Searcher
              name="menusPart"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allEntities={allAssociatedEntities}
              setPartEntities={setSelectedDish}
              disabled={isInputDisabled}
            />

            {selectedDish && (
              <Form.Group className="mb-3">
                <Form.Label className="d-block">
                  {`${selectedDish.category} --> ${selectedDish.title}`}
                </Form.Label>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleAddDish(selectedDish)}
                >
                  Ajouter
                </Button>
              </Form.Group>
            )}
            <div>
              {associatedEntitiesIds.length > 0
              && <p className="text-decoration-underline fw-bolder">Plats du menu :</p>}
              {dishCategoryChoice
                .filter(
                  (dishCategory) => associatedEntitiesIds.some(
                    (dishId) => getEntityProperty(
                      dishId,
                      'category',
                      allAssociatedEntities,
                    ) === dishCategory,
                  ),
                )
                .map((dishCategory, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <p className="fw-bolder">{dishCategory}</p>
                    <ul>
                      {associatedEntitiesIds
                        .filter(
                          (dishId) => getEntityProperty(
                            dishId,
                            'category',
                            allAssociatedEntities,
                          ) === dishCategory,
                        )
                        .map((dishId) => (
                          <li key={dishId} className="mb-2">
                            {getEntityProperty(dishId, 'title', allAssociatedEntities)}
                            {!isInputDisabled
                            && (
                            <Button
                              className="ms-4"
                              variant="primary"
                              size="sm"
                              onClick={() => handleRemoveDish(dishId)}
                            >
                              Retirer
                            </Button>
                            )}
                          </li>
                        ))}
                    </ul>
                  </React.Fragment>
                ))}
            </div>

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image du menu (440 * 560)</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} disabled={isInputDisabled} />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              {!isInputDisabled ? (
                <>
                  <Button
                    variant="success"
                    size="sm"
                    className="me-4"
                    type="submit"
                    disabled={isAssociatedEntitiesEmpty}
                  >
                    Valider
                  </Button>

                  <Button variant="primary" size="sm" onClick={handleClose}>
                    Annuler
                  </Button>
                </>
              ) : (
                <Button variant="primary" size="sm" onClick={handleClose}>
                  Retour
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

MenuFormPart.propTypes = {
  isAddModal: PropTypes.bool.isRequired,
  associatedEntitiesIds: PropTypes.arrayOf(
    PropTypes.number.isRequired,
  ),
  setAssociatedEntitiesIds: PropTypes.func.isRequired,
  allAssociatedEntities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  selectedEntryColumns: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    category: PropTypes.string,
    isSaled: PropTypes.bool,
  }),
  setSelectedEntryColumns: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

MenuFormPart.defaultProps = {
  associatedEntitiesIds: null,
  selectedEntryColumns: null,
};

export default MenuFormPart;

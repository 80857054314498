/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// Méthodes des ressources autorisées à modifier le cache
const allowedMethodsByURLForResource = {
  'https://ti-pei-gourmand.fr/api/users': ['POST', 'PATCH', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/dishes': ['POST', 'PATCH', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/menus': ['POST', 'PATCH', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/presentation_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/about_us_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/special_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/menu_options': ['PATCH'],
  'https://ti-pei-gourmand.fr/api/team_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/gallery_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/footer_options': ['POST', 'PATCH'],
  'https://ti-pei-gourmand.fr/api/restaurant_options': ['PATCH'],
};

// Contient les url des ressources qui sont liées à mercure. Utiliser le cache pose problème
const excludeUrlForCache = [
  'https://ti-pei-gourmand.fr/api/tables',
  'https://ti-pei-gourmand.fr/api/orders',
  'https://ti-pei-gourmand.fr/api/ingredients',
  'https://ti-pei-gourmand.fr/api/usersPlanning',
];

// Méthodes des ressources de composition autorisées à modifier le cache des ressources associées
const allowedMethodsByURLForComposition = {
  'https://ti-pei-gourmand.fr/api/user_roles': ['POST', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/role_permissions': ['POST', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/dish_ingredients': ['POST', 'DELETE'],
  'https://ti-pei-gourmand.fr/api/menu_dishes': ['POST', 'DELETE'],
};

// Méthode des ressources dont les entrées peuvent être modifiées en groupe
const allowedMethodsByURLForMultipleResources = {
  'https://ti-pei-gourmand.fr/api/team_members': ['PUT'],
  'https://ti-pei-gourmand.fr/api/gallery_images': ['PUT'],
};

// Méthode convertissant les dates null en ''
function dateFromServerProcessor(resourceDataFromServer) {
  const updatedHireDateFromServer = resourceDataFromServer.hireDate ? resourceDataFromServer.hireDate : '';
  const updatedEndDateFromServer = resourceDataFromServer.endDate ? resourceDataFromServer.endDate : '';

  return {
    ...resourceDataFromServer,
    hireDate: updatedHireDateFromServer,
    endDate: updatedEndDateFromServer,
  };
}

// Permet, dans le cas d'un PUT ou d'un PATCH, d'extraire l'id de le ressource modifié via l'URL
const getResourceIdFromUrl = (url) => {
  const parts = url.split('/');

  // L'ID de l'utilisateur est le dernier segment de l'URL
  return parseInt(parts[parts.length - 1], 10);
};

// Permet de match une composition avec une des ressources associée
const transformUrlFromComposition = (url) => {
  switch (url) {
    case 'https://ti-pei-gourmand.fr/api/user_roles':
      return 'https://ti-pei-gourmand.fr/api/users';
    case 'https://ti-pei-gourmand.fr/api/role_permissions':
      return 'https://ti-pei-gourmand.fr/api/roles';
    case 'https://ti-pei-gourmand.fr/api/dish_ingredients':
      return 'https://ti-pei-gourmand.fr/api/dishes';
    case 'https://ti-pei-gourmand.fr/api/menu_dishes':
      return 'https://ti-pei-gourmand.fr/api/menus';
    default:
      return url;
  }
};

// Permet de récupérer le nom de la ressource visée par la requête
const getResourceName = (url) => {
  switch (url) {
    case 'https://ti-pei-gourmand.fr/api/users':
      return 'user';
    case 'https://ti-pei-gourmand.fr/api/roles':
      return 'role';
    case 'https://ti-pei-gourmand.fr/api/permissions':
      return 'permission';
    case 'https://ti-pei-gourmand.fr/api/dishes':
      return 'dish';
    case 'https://ti-pei-gourmand.fr/api/ingredients':
      return 'ingredient';
    case 'https://ti-pei-gourmand.fr/api/menus':
      return 'menu';
    case 'https://ti-pei-gourmand.fr/api/user_roles':
      return 'userRoles';
    case 'https://ti-pei-gourmand.fr/api/role_permissions':
      return 'rolePermissions';
    case 'https://ti-pei-gourmand.fr/api/dish_ingredients':
      return 'dishIngredients';
    case 'https://ti-pei-gourmand.fr/api/menu_dishes':
      return 'menuDishes';
    case 'https://ti-pei-gourmand.fr/api/presentation_options':
      return 'presentationoption';
    case 'https://ti-pei-gourmand.fr/api/about_us_options':
      return 'aboutusoption';
    case 'https://ti-pei-gourmand.fr/api/special_options':
      return 'specialoption';
    case 'https://ti-pei-gourmand.fr/api/menu_options':
      return 'menuoption';
    case 'https://ti-pei-gourmand.fr/api/team_options':
      return 'teamoption';
    case 'https://ti-pei-gourmand.fr/api/team_members':
      return 'teamMembers';
    case 'https://ti-pei-gourmand.fr/api/gallery_options':
      return 'galleryoption';
    case 'https://ti-pei-gourmand.fr/api/gallery_images':
      return 'galleryImages';
    case 'https://ti-pei-gourmand.fr/api/footer_options':
      return 'footeroption';
    case 'https://ti-pei-gourmand.fr/api/tables':
      return 'table';
    case 'https://ti-pei-gourmand.fr/api/restaurant_options':
      return 'restaurantoption';
    default:
      return url;
  }
};

// Méthode permettant d'ajouter ou de remplacer des informations au cache
const updateCacheForResource = (resourceUrl, updatedMembers, resourceDataInCache, updateCache) => {
  // Je recrée mon cache en recopiant son contenu et en ajoutant le hydra:member modifié
  const updatedCache = {
    ...resourceDataInCache,
    'hydra:member': updatedMembers,
  };

  updateCache(resourceUrl, updatedCache);
};

/* *********************************************************** */
// ENTIÈREMENT FONCTIONNEL
// Méthode spécifique à PUT et PATCH et visant à construire les informations à ajouter au cache
const handlePutOrPatchRequestLogic = (data, resourceUrl, resourceId, cache, updateCache) => {
  // Je récupère le nom de la ressource en cours de modification
  const resourceName = getResourceName(resourceUrl);

  // Je récupère les informations actuellement en cache pour ma ressource
  const resourceDataInCache = cache[resourceUrl];

  let updatedMembers;

  if (Array.isArray(data)) {
    updatedMembers = resourceDataInCache['hydra:member'];

    data.forEach((element) => {
      // Pour chaque element je .map je mets à jour updatedMembers
      updatedMembers = updatedMembers.map((resource) => {
        // Si l'identifiant correspond
        if (resource.id === element.id) {
          /*
            Traitement des dates, puis remplacement du contenu du hydra
            member de ce membre par ce que j'ai reçu de mon serveur
          */
          return resourceName === 'user' ? dateFromServerProcessor(element) : element;
        }
        // Si l'identifiant ne correspond pas, retourner l'élément inchangé
        return resource;
      });
    });
    updateCacheForResource(resourceUrl, updatedMembers, resourceDataInCache, updateCache);
  } else {
    // Je récupère les informations concernant la modification de la ressource
    const { '@context': ignoredAtContext, ...formedData } = data;
    // Récupère plus précisément les info hydra:member actuellement en cache pour ma ressource
    updatedMembers = resourceDataInCache['hydra:member'].map((resource) => {
      // Puis quand je trouve la ressource modifiée repérable par l'id
      if (resource.id === resourceId) {
        /*
          Traitement des dates puis je remplace le contenu du hydra
          member de ce membre par ce que j'ai reçu de mon serveur
        */
        return resourceName === 'user' ? dateFromServerProcessor(formedData) : formedData;
      }
      return resource;
    });
    updateCacheForResource(resourceUrl, updatedMembers, resourceDataInCache, updateCache);
  }
};

// ENTIÈREMENT FONCTIONNEL
const handleMultiplePutOrPatchLogic = (data, resourceUrl, cache, updateCache) => {
  const resourceName = getResourceName(resourceUrl);

  const resourceDataFromPut = data[resourceName];
  let resourceToModifydUrl;

  if (resourceName === 'teamMembers') {
    resourceToModifydUrl = 'https://ti-pei-gourmand.fr/api/team_options';
  } else if (resourceName === 'galleryImages') {
    resourceToModifydUrl = 'https://ti-pei-gourmand.fr/api/gallery_options';
  }

  const resourceDataInCache = cache[resourceToModifydUrl];

  // Copie le tableau récupéré du serveur à la place de celui existant
  resourceDataInCache['hydra:member'][0][resourceName] = resourceDataFromPut;
  updateCache(resourceToModifydUrl, resourceDataInCache);
};

// Méthode spécifique à POST sur les entités et construisant les informations à ajouter au cache
// ENTIÈREMENT FONCTIONNEL
const handleClassicPostRequestLogic = (url, data, cache, updateCache) => {
  const resourceName = getResourceName(url);

  const { '@context': ignoredAtContext, ...formedData } = data;

  // Je récupère les informations concernant l'ajout de la ressource
  const resourceDataFromPost = formedData;

  // Je récupère les informations actuellement en cache pour ma ressource
  const resourceDataInCache = cache[url];

  // Récupère plus précisément les informations hydra:member actuellement en cache pour ma ressource
  const resourceDataInCacheMembers = resourceDataInCache['hydra:member'];

  /*
    Traitement des dates puis je remplace le contenu du hydra
    member de ce membre par ce que j'ai reçu de mon serveur
  */
  const updatedMembers = resourceName === 'user' ? [
    ...resourceDataInCacheMembers,
    dateFromServerProcessor(resourceDataFromPost),
  ] : [
    ...resourceDataInCacheMembers,
    resourceDataFromPost,
  ];

  updateCacheForResource(url, updatedMembers, resourceDataInCache, updateCache);
};

// Méthode spécifique à POST sur les compositions et construisant les infos à ajouter au cache
const handleCompositionPostRequestLogic = (url, data, cache, updateCache) => {
  // Je récupère le nom de la ressource de composition en cours d'ajout
  const resourceCompoName = getResourceName(url);

  // Je déduis l'url de la ressource affectée par la composition
  const transformedUrl = transformUrlFromComposition(url);

  // Je récupère le nom de la ressource affectée par l'ajout de composition
  const resourceName = getResourceName(transformedUrl);

  // Je récupère l'id de la ressource affectée par l'ajout de composition
  const resourceIdFromPost = data[resourceName];

  // Je récupère les informations concernant l'ajout de composition
  const compoAddedFromPost = data.compositionsAdded;

  // Je récupère les informations actuellement en cache pour ma ressource
  const resourceDataInCache = cache[transformedUrl];

  // Récupère plus précisément les infos hydra:member actuellement en cache pour ma ressource
  const updatedMembers = resourceDataInCache['hydra:member'].map((resource) => {
    // Puis quand je trouve la ressource modifiée repérable par l'id
    if (resource.id === resourceIdFromPost) {
      // Si l'entité manipulée est dish
      if (resourceName === 'dish') {
        const updatedResource = { ...resource };
        // Je regarde toutes les nouvelles associations
        compoAddedFromPost.forEach((compo) => {
          // Je regarde si cette association existe déjà et j'en prend l'index si oui
          const existingIndex = updatedResource[resourceCompoName].findIndex(
            (existingCompo) => existingCompo.id === compo.id,
          );

          // Si elle existait déjà, je remplace son contenu
          if (existingIndex !== -1) {
            updatedResource[resourceCompoName][existingIndex] = compo;
            // Si ce n'est pas le cas je l'ajoute
          } else {
            updatedResource[resourceCompoName].push(compo);
          }
        });
        return updatedResource;
      }
      // J'ajoute les informations de composition au cache
      resource[resourceCompoName].push(...compoAddedFromPost);
    }
    return resource;
  });

  updateCacheForResource(transformedUrl, updatedMembers, resourceDataInCache, updateCache);
};

const handleClassicDeleteRequestLogic = (url, data, cache, updateCache) => {
  const resourceName = getResourceName(url);

  // Je récupère les informations actuellement en cache pour ma ressource
  const resourceDataInCache = cache[url];

  // Je récupère l'id de la ou les ressources affectées par la suppression
  const resourceIdFromDelete = data[resourceName];

  // Garde que les membres de hydra:member dont l'id ne se trouve pas dans le tableau d'id supp
  const updatedMembers = resourceDataInCache['hydra:member'].filter(
    (member) => !resourceIdFromDelete.includes(member.id),
  );

  updateCacheForResource(url, updatedMembers, resourceDataInCache, updateCache);
};

// Méthode spécifique à DELETE sur les compositions et visant à supprimer des informations du cache
const handleCompositionDeleteRequestLogic = (url, data, cache, updateCache) => {
  // Je récupère le nom de la ressource de composition en cours de suppression
  const resourceCompoName = getResourceName(url);

  // Je déduis l'url de la ressource affectée par la composition
  const transformedUrl = transformUrlFromComposition(url);

  // Je récupère le nom de la ressource affectée par la suppression de composition
  const resourceName = getResourceName(transformedUrl);

  // Je récupère l'id de la ressource affectée par la suppression de composition
  const resourceIdFromDelete = data[resourceName];

  // Je récupère les ids des compositions à supprimer
  const compoRemovedFromDelete = data.compositionsRemoved;

  // Je récupère les informations actuellement en cache pour ma ressource
  const resourceDataInCache = cache[transformedUrl];

  // Récupère plus précisément les infos hydra:member actuellement en cache pour ma ressource
  const updatedMembers = resourceDataInCache['hydra:member'].map((resource) => {
    // Créez une copie de l'objet resource
    const updatedResource = { ...resource };

    // Si l'ID de la ressource correspond à resourceIdFromDelete
    if (updatedResource.id === resourceIdFromDelete) {
      // Filtrer les informations de composition basées sur les IDs retirés
      updatedResource[resourceCompoName] = updatedResource[resourceCompoName].filter(
        (resourceCompo) => !compoRemovedFromDelete.includes(resourceCompo.id),
      );
    }

    return updatedResource; // Retourner la copie modifiée de la ressource
  });

  updateCacheForResource(transformedUrl, updatedMembers, resourceDataInCache, updateCache);
};

/* *********************************************************** */

const handleGetRequest = (url, data, response, updateCache) => {
  // Je mets le cache à jour
  updateCache(url, data);

  // Je renvoie la réponse
  return { data, response };
};

const handlePutOrPatchRequest = (url, data, response, cache, updateCache, options) => {
  // Expression régulière pour vérifier si l'URL se termine par un nombre (ID)
  const regex = /\/(\d+)$/;

  // Vérification si l'URL se termine par un nombre (ID)
  const hasId = regex.test(url);
  let resourceUrl;
  let resourceId;

  if (hasId) {
    // Je récupère l'url de la ressource sans l'id
    resourceUrl = url.substring(0, url.lastIndexOf('/'));

    // Je récupère l'id de la ressource
    resourceId = getResourceIdFromUrl(url);
  } else {
    resourceUrl = url;
  }

  // Si la modification de la resource par la méthode options.methods est autorisée
  if (allowedMethodsByURLForResource[resourceUrl]?.includes(options.method)) {
    // J'effectue la modification du cache
    handlePutOrPatchRequestLogic(data, resourceUrl, resourceId, cache, updateCache);
  } else if (allowedMethodsByURLForMultipleResources[resourceUrl]?.includes(options.method)) {
    handleMultiplePutOrPatchLogic(data, resourceUrl, cache, updateCache);
  }

  // Je renvoie la réponse
  return { data, response };
};

const handlePostRequest = (url, data, response, cache, updateCache, options) => {
  // Si la modification de la resource par la méthode options.methods est autorisée
  if (allowedMethodsByURLForResource[url]?.includes(options.method)) {
    // J'effectue la modification du cache
    handleClassicPostRequestLogic(url, data, cache, updateCache);
  } else if (allowedMethodsByURLForComposition[url]?.includes(options.method)) {
    // J'effectue la modification du cache
    handleCompositionPostRequestLogic(url, data, cache, updateCache);
  }

  return { data, response };
};

const handleDeleteRequest = (url, data, response, cache, updateCache, options) => {
  if (allowedMethodsByURLForResource[url]?.includes(options.method)) {
    // J'effectue la modification du cache
    handleClassicDeleteRequestLogic(url, data, cache, updateCache);
  } else if (allowedMethodsByURLForComposition[url]?.includes(options.method)) {
    // J'effectue la modification du cache
    handleCompositionDeleteRequestLogic(url, data, cache, updateCache);
  }

  return { data, response };
};

/* *********************************************************** */

const methodSelection = (url, data, response, cache, updateCache, options) => {
  // On vérifie si l'url commence comme celles qui sont exclues
  const isExcluded = excludeUrlForCache.some((excludeUrl) => url.startsWith(excludeUrl));

  // Si on a une requête GET qui n'a pas encore sa réponse en cache et qui n'est pas exclue
  if (options.method === 'GET' && !isExcluded) {
    return handleGetRequest(url, data, response, updateCache);
    // Si on est dans le cas d'une modification
  } if (['PUT', 'PATCH'].includes(options.method)) {
    return handlePutOrPatchRequest(url, data, response, cache, updateCache, options);

    // Si on est dans le cas d'un ajout
  } if (options.method === 'POST') {
    return handlePostRequest(url, data, response, cache, updateCache, options);

    // Si on est dans le cas d'une suppression
  } if (options.method === 'DELETE') {
    return handleDeleteRequest(url, data, response, cache, updateCache, options);

    // Dans tous les autres cas on ne fait rien et on se contente d'envoyer les données et la réponse
  }
  return { data, response };
};

export default methodSelection;

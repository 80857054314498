import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import GenericOption from '../Generic/GenericOption';

const OptionsTitle = styled.h6`
  margin-bottom: 33.6px;
`;

function ThemeManagement() {
  return (
    <Container fluid className="pt-4 px-4">
      <div className="bg-secondary rounded h-100 p-4">
        <div className="themeOptions">
          <OptionsTitle>Options de personnalisation du thème</OptionsTitle>
          <GenericOption name="presentation_options" />
          <GenericOption name="about_us_options" />
          <GenericOption name="special_options" />
          <GenericOption name="menu_options" />
          <GenericOption name="team_options" />
          <GenericOption name="gallery_options" />
          <GenericOption name="footer_options" />
        </div>
      </div>
    </Container>
  );
}

export default ThemeManagement;

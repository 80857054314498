import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';

function AboutUs({ aboutUsOptions }) {
  return (
    <div id="about" className="about-main pad-top-100 pad-bottom-100">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
              <h2 className="block-title">{aboutUsOptions.title}</h2>
              <h3>{aboutUsOptions.subtitle}</h3>
              <p style={{ whiteSpace: 'pre-line' }}>{aboutUsOptions.description}</p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
              <div className="about-images">
                <img className="about-main" src={`https://ti-pei-gourmand.fr/images/aboutUsOptions/${aboutUsOptions.mainImg}`} alt="About Main Img" />
                <img className="about-inset" src={`https://ti-pei-gourmand.fr/images/aboutUsOptions/${aboutUsOptions.secondaryImg}`} alt="About Inset Img" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

AboutUs.propTypes = {
  aboutUsOptions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    mainImg: PropTypes.string,
    secondaryImg: PropTypes.string,
  }).isRequired,
};

export default AboutUs;

// Méthode renvoyant une partie du corps de la requête
const getBodyData = (name, selectedEntryColumns) => {
  switch (name) {
    case 'ingredients':
      return {
        title: selectedEntryColumns.title,
        quantity: selectedEntryColumns.quantity,
        maxQuantity: selectedEntryColumns.maxQuantity,
        unit: selectedEntryColumns.unit,
        category: selectedEntryColumns.category,
        isAllergen: selectedEntryColumns.isAllergen,
        alert: selectedEntryColumns.alert,
        increaseMode: selectedEntryColumns.increaseMode,
        increaseInterval: selectedEntryColumns.increaseInterval,
        isAdded: selectedEntryColumns.isAdded,
        quantityIncreased: selectedEntryColumns.quantityIncreased,
        startingDay: (
          selectedEntryColumns.startingDay === '' || selectedEntryColumns.startingDay === 'NaN-NaN-NaN'
        ) ? null : selectedEntryColumns.startingDay,
      };
    default:
      return '';
  }
};

export default getBodyData;

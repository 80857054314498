import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useApi, useModalManagement } from '../../../../utils/hooks/admin';
import {
  getEntryColumns,
  getInitialAssociatedEntitiesIds,
  getBodyData,
} from '../../../../utils/helpers/Generic/GenericAssociatedAddAndEditModal';
import UserFormPart from '../../FormPart/UserFormPart';
import DishFormPart from '../../FormPart/DishFormPart';
import MenuFormPart from '../../FormPart/MenuFormPart';

function GenericAssociatedAddAndEditModal({
  name, // Nom de l'entité associée à la modale
  associatedName, // Nom de la 2ème entité associée à la modale
  handleClose,
  handleSuccess,
  selectedEntry, // Objet contenant les informations de l'entité
}) {
  const {
    fetchData,
    authToken,
    updateUserAuth,
    updateAssociativeEntity,
    authId,
    setAuthImg,
  } = useApi();
  const { handleSuccessInModal } = useModalManagement();

  // Objet ne contenant que certaines propriétés de celui passé en prop
  const entryColumns = getEntryColumns(name, selectedEntry);

  // Tableau contenant l'ensemble des ids d'entités secondaires initialement attribuées
  const initialAssociatedEntitiesIds = getInitialAssociatedEntitiesIds(name, selectedEntry);

  // Permet de déterminer le mode de la modale (ajout ou édition)
  const isAddModal = !!selectedEntry;

  // State contenant les informations de mon entité
  const [selectedEntryColumns, setSelectedEntryColumns] = useState(entryColumns);

  // State contenant l'intégralité des entités secondaires qu'il est possible d'attribuer
  const [allAssociatedEntities, setAllAssociatedEntities] = useState([]);

  // State contenant l'ensemble des ids d'entités secondaires actuellement attribuées
  const [associatedEntitiesIds, setAssociatedEntitiesIds] = useState(initialAssociatedEntitiesIds);

  // Contient la réponse de la requête d'inscription ou de modification de l'entité
  const [entityResponse, setEntityResponse] = useState(null);

  // Contient les informations à envoyer concernant l'image donnée par l'utilisateur
  const [imageInfos, setImageInfos] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  // Méthode permettant de mettre à jour le state avec les informations du fichier image donné
  const handleImageChange = (e) => {
    // On récupère le fichier passé
    const file = e.target.files[0];

    // On met à jour notre state avec le contenu du fichier et son nom
    setImageInfos({
      imageFile: file,
      imageName: file.name,
    });
  };

  // Requête l'API à la soumission du formulaire
  const handleSubmit = async (e) => {
    // Mes requêtes vont s'effectuer, j'affiche mon loading
    setIsLoading(true);

    // On retire le comportement par défaut du formulaire
    e.preventDefault();

    // Contiendra le fichier. FormData est obligatoire pour pouvoir le transmettre à l'API
    const ImageformData = new FormData();

    // Si une image a été spécifiée
    if (imageInfos !== null) {
      // On met à jour notre entité en lui ajoutant les information de l'image
      ImageformData.append('image', imageInfos.imageFile);
      ImageformData.append('imageName', imageInfos.imageName);
    }

    // Contient une partie du corps de la requête
    const bodyData = getBodyData(name, selectedEntryColumns, isAddModal);

    // Informations nécessaires pour la requête
    const entityOptions = {
      method: selectedEntry ? 'PATCH' : 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': selectedEntry ? 'application/merge-patch+json' : 'application/ld+json',
      },
      body: JSON.stringify(bodyData),
    };

    // Méthode requêtant l'API
    const submitEntity = async () => {
      /* ************ TRAITEMENT DE L'AJOUT / MODIF DE L'ENTITÉ  ************ */

      // Contient l'url à atteindre pour effectuer l'ajout ou la modification
      const entityUrl = selectedEntry
        ? `https://ti-pei-gourmand.fr/api/${name}/${selectedEntry.id}`
        : `https://ti-pei-gourmand.fr/api/${name}`;

      // On requête l'API pour traiter l'inscription ou la modification de l'entité
      const { data: entityData, response } = await fetchData(entityUrl, entityOptions);

      if (!entityData) {
        setIsLoading(false);
        return;
      }

      // Dans le cas de l'ajout / modif d'un utilisateur, un token sera renvoyé
      if (entityData.token) {
        /*
          Stockage de l'objet authToken dans le LocalStorage et
          Maj des valeurs partagées par le context ApiContext
        */
        updateUserAuth(entityData.token);
      }

      /* ************ FIN DU TRAITEMENT DE L'AJOUT / MODIF DE L'ENTITÉ ************ */

      /* --------------------------------------------------- */

      /* ************ TRAITEMENT DE L'IMAGE ************ */

      // Si une image a été spécifiée
      if (imageInfos !== null) {
        const imagePostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: ImageformData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `https://ti-pei-gourmand.fr/api/${name}/${entityData.id}`;

        // On requête l'API pour inscrire la nouvelle image
        const { data: imgData } = await fetchData(imagePostUrl, imagePostOptions);

        // Si l'image modifiée est celle du user connecté
        if (name === 'users' && entityData.id === authId) {
          // Je mets à jour son image
          setAuthImg(imgData);
        }
      }

      /* ************ FIN DU TRAITEMENT DE L'IMAGE ************ */

      setEntityResponse({ entityData, response });
    };

    submitEntity();
  };

  /* ************ TRAITEMENT DES ASSOCIATIONS ************ */

  // Quand l'entité a fini d'être ajoutée ou modifiée, on va mettre à jour ses associations
  useEffect(() => {
    const fetchAssociatedEntitiesData = async () => {
      switch (name) {
        case 'users':
          await updateAssociativeEntity(
            'user_roles',
            entityResponse.entityData.id,
            associatedEntitiesIds,
            initialAssociatedEntitiesIds,
            name,
          );
          break;
        case 'dishes':
          await updateAssociativeEntity(
            'dish_ingredients',
            entityResponse.entityData.id,
            associatedEntitiesIds,
            initialAssociatedEntitiesIds,
            name,
          );
          break;
        case 'menus':
          await updateAssociativeEntity(
            'menu_dishes',
            entityResponse.entityData.id,
            associatedEntitiesIds,
            initialAssociatedEntitiesIds,
            name,
          );
          break;
        default:
          break;
      }

      // Le processus lié à la modale est terminé, on va fermer cette dernière
      handleSuccessInModal(entityResponse.response, handleClose, handleSuccess, setIsLoading);

      setEntityResponse(null);
    };

    if (entityResponse) {
      fetchAssociatedEntitiesData();
    }
  }, [entityResponse]);

  /* ************ FIN DU TRAITEMENT DES ASSOCIATIONS ************ */

  useEffect(() => {
    // Méthode permettant l'appel API
    const fechAssociatedEntityDataAsync = async () => {
      // Informations nécessaires pour la requête
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      // Interroge l'API récupérant ainsi toutes les entités secondaires attribuables
      const {
        data: associatedEntitiesData,
      } = await fetchData(`https://ti-pei-gourmand.fr/api/${associatedName}`, options);

      // Je mets à jour le state contenant toutes les entités secondaires attribuables
      setAllAssociatedEntities(associatedEntitiesData['hydra:member'].map((associatedEntity) => ({
        ...associatedEntity,
      })));

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    };

    fechAssociatedEntityDataAsync();
  }, [authToken]);

  return (
    <>
      {name === 'users' && (
        <UserFormPart
          isAddModal={isAddModal}
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          associatedEntitiesIds={associatedEntitiesIds}
          setAssociatedEntitiesIds={setAssociatedEntitiesIds}
          allAssociatedEntities={allAssociatedEntities}
          isLoading={isLoading}
          handleImageChange={handleImageChange}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
      {name === 'dishes' && (
        <DishFormPart
          isAddModal={isAddModal}
          associatedEntitiesIds={associatedEntitiesIds}
          setAssociatedEntitiesIds={setAssociatedEntitiesIds}
          allAssociatedEntities={allAssociatedEntities}
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          isLoading={isLoading}
          handleImageChange={handleImageChange}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
      {name === 'menus' && (
        <MenuFormPart
          isAddModal={isAddModal}
          associatedEntitiesIds={associatedEntitiesIds}
          setAssociatedEntitiesIds={setAssociatedEntitiesIds}
          allAssociatedEntities={allAssociatedEntities}
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          isLoading={isLoading}
          handleImageChange={handleImageChange}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

GenericAssociatedAddAndEditModal.propTypes = {
  name: PropTypes.string.isRequired,
  associatedName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  selectedEntry: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      realName: PropTypes.string,
      password: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      employmentStatus: PropTypes.string,
      socialSecurityNumber: PropTypes.string,
      comments: PropTypes.string,
      imageFile: PropTypes.instanceOf(File),
      imageName: PropTypes.string,
      hireDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.number,
      category: PropTypes.string,
    }),
  ]),
};

GenericAssociatedAddAndEditModal.defaultProps = {
  selectedEntry: null,
};

export default GenericAssociatedAddAndEditModal;

import React from 'react';
import OrdersManagement from '../OrdersManagement';

function OrderListManagement() {
  return (
    <OrdersManagement mode="AllCaisse" />
  );
}

export default OrderListManagement;

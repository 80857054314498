// Tableau contenant toutes les catégories d'ingrédient qu'il est possible de trouver
export const ingredientCategoryChoice = [
  'Alcools',
  'Céréales',
  'Condiments',
  'Confiseries',
  'Crustacés',
  'Édulcorants',
  'Farines',
  'Fruits',
  'Herbes aromatiques',
  'Huiles',
  'Laits alternatifs',
  'Légumes',
  'Pâtes',
  'Poissons',
  'Produits animal',
  'Produits laitiers',
  'Sirops',
  'Viandes',
  'Volailles',
  'Autres',
];

// Tableau contenant toutes les catégories de plats qu'il est possible d'attribuer
export const dishCategoryChoice = [
  'Entrée',
  'Plat principal',
  'Accompagnement',
  'Dessert',
];

// Permet de déterminer le titre d'une modale
export const getModalTitle = (isAddModal, isInputDisabled) => {
  if (isAddModal) {
    if (isInputDisabled) {
      return 'Infos complémentaires ';
    }
    return 'Édition ';
  }
  return 'Ajout ';
};

// Méthode permettant d'obtenir la valeur de la propriété d'un ingrédient
export const getEntityProperty = (entityId, property, allAssociatedEntities) => {
  const entity = allAssociatedEntities.find((item) => item.id === entityId);

  return entity ? entity[property] : '';
};

import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useApi } from '../../../../utils/hooks/admin';
import SpinnerWrapper from '../../SpinnerWrapper';
import PresentationOptionsFormPart from '../../FormPart/PresentationOptionsFormPart';
import AboutUsOptionsFormPart from '../../FormPart/AboutUsOptionsFormPart';
import SpecialOptionsFormPart from '../../FormPart/SpecialOptionsFormPart';
import { optionsBuilder } from '../../../../utils/helpers/FormPart/SpecialOptionsFormPart';
import {
  getBodyData,
  getDefaultEntryColumns,
  getImageBodyForm,
  getUrl,
} from '../../../../utils/helpers/Generic/GenericOption';
import MenuOptionsFormPart from '../../FormPart/MenuOptionsFormPart';
import TeamOptionsFormPart from '../../FormPart/TeamOptionsFormPart';
import GalleryOptionsFormPart from '../../FormPart/GalleryOptionsFormPart';
import FooterOptionsFormPart from '../../FormPart/FooterOptionsFormPart';
import RestaurantOptionsFormPart from '../../FormPart/RestaurantOptionsFormPart';

function GenericOption({ name }) {
  const { fetchData, authToken } = useApi();

  const [isLoading, setIsLoading] = useState(true);

  // State contenant le tableau de tous les objets entité à afficher
  // IL EST IMPORTANT DE DONNER DES VALEURS PAR DÉFAUT QUAND UN OBJET EST ASSOCIÉ À UN FORMULAIRE
  const [entries, setEntries] = useState(getDefaultEntryColumns(name));

  // Permet de savoir quand le formulaire est soumis
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Contient les informations à envoyer concernant l'image donnée par l'utilisateur
  const [imageInfos, setImageInfos] = useState({});

  // Contient les informations à envoyer concernant les images de l'entité associée
  const [imageMemberInfos, setImageMemberInfos] = useState({});

  // Contient les éléments actuellement associés au carousel de special_options
  const [productsDisplayed, setProductsDisplayed] = useState([]);

  // Contient les éléménts initialement associés au carousel de special_options
  const [initialProductsDisplayed, setInitialProductsDisplayed] = useState([]);

  // Contient les éléments ajoutés et supprimés du carousel de special_options
  const [finalDishesAdded, setFinalDishesAdded] = useState([]);
  const [isDishTreated, setIsDishTreated] = useState(false);
  const [finalDishesRemoved, setFinalDishesRemoved] = useState([]);
  const [finalMenusAdded, setFinalMenusAdded] = useState([]);
  const [isMenuTreated, setIsMenuTreated] = useState(false);
  const [finalMenusRemoved, setFinalMenusRemoved] = useState([]);

  // Permet de ne traiter la 2ème entité que lorsque la 1ère l'a été
  const [firstResponse, setFirstResponse] = useState({});

  // Méthode pour gérer le changement d'image pour un champ spécifique
  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    // Pour chaque image du formulaire, un objet lui est dédié et il est identifié par son field
    setImageInfos((prevImageInfos) => ({
      ...prevImageInfos,
      [fieldName]: {
        imageFile: file,
        imageName: file.name,
      },
    }));
  };

  // Requête l'API à la soumission du formulaire
  const handleSubmit = async (e) => {
    // Mes requêtes vont s'effectuer, j'affiche mon loading
    setIsLoading(true);

    if (e) {
      // On retire le comportement par défaut du formulaire
      e.preventDefault();
    }

    if (name === 'team_options') {
      // Je replace les membres dans le bon ordre
      const updatedEntries = { ...entries };

      updatedEntries.teamMembers = entries.teamMembers.sort((a, b) => a.id - b.id);
      setEntries(updatedEntries);
    }

    setFormSubmitted(true);
  };

  useEffect(() => {
    /* ************ TRAITEMENT DE LA 1ERE ENTITÉ  ************ */
    const submitFirstEntity = async () => {
      // Informations nécessaires pour la requête
      const entityOptions = optionsBuilder(authToken, entries, 'PATCH');

      const entityUrl = `https://ti-pei-gourmand.fr/api/${name}/${entries.id}`;

      const { response } = await fetchData(entityUrl, entityOptions);

      // Ma première entité a été traitée. Si il y en a une autre, elle va pouvoir l'être aussi
      setFirstResponse(response);
    };

    if (formSubmitted && Object.keys(entries).length > 0) {
      submitFirstEntity();
    }
  }, [entries, formSubmitted]);

  useEffect(() => {
    const submitSecondaryEntity = async () => {
      // FormData à envoyer qui contient le ou les images de l'entité
      let formData = new FormData();

      /* ************ TRAITEMENT DE L'ENTITÉ ASSOCIÉE  ************ */
      if (name === 'team_options' || name === 'gallery_options') {
        const secondaryEntityPutOptions = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(getBodyData(name, entries)),
        };

        const secondaryEntityPutUrl = getUrl(name);

        await fetchData(secondaryEntityPutUrl, secondaryEntityPutOptions);
      }

      /* ************ FIN DU TRAITEMENT DES ENTITÉS ************ */

      /* --------------------------------------------------- */

      /* ************ TRAITEMENT DES IMAGES DE L'ENTITÉ ************ */

      if (Object.keys(imageInfos).length > 0) {
        getImageBodyForm(formData, imageInfos);

        const imagePostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `https://ti-pei-gourmand.fr/api/${name}/${entries.id}`;

        // On requête l'API pour inscrire la nouvelle image
        await fetchData(imagePostUrl, imagePostOptions);

        // FormData va être réutilisé dans le traitement des images suivantes. Je le vide donc.
        formData = new FormData();
        setImageInfos({});
      }

      /* ************ TRAITEMENT DES IMAGES DE L'ENTITÉ ASSOCIÉE ************ */

      if (Object.keys(imageMemberInfos).length > 0) {
        getImageBodyForm(formData, imageMemberInfos, name);
        // Envoi de la requête avec les données FormData
        const imageMemberPostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: formData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = `https://ti-pei-gourmand.fr/api/${name === 'team_options' ? 'team_members' : 'gallery_images'}`;

        // On requête l'API pour inscrire la nouvelle image
        await fetchData(imagePostUrl, imageMemberPostOptions);

        formData = new FormData();
        setImageMemberInfos({});
      }

      /* ************ FIN DU TRAITEMENT DES IMAGES DES ENTITÉS ************ */

      /* ************ TRAITEMENT DES AJOUTS / SUPP DES ÉLÉMENTS DU CAROUSEL ************ */

      // Si je traite le formulaire du carousel
      if (name === 'special_options') {
        // Si au moins 1 élément apparaît dans la liste de ceux ajoutés
        if (finalDishesAdded.length > 0) {
          const options = optionsBuilder(authToken, { operation: 'add', entries: finalDishesAdded }, 'PATCH');
          const url = 'https://ti-pei-gourmand.fr/api/dishes';

          await fetchData(url, options);
          setIsDishTreated(true);
        }
        if (finalMenusAdded.length > 0) {
          const options = optionsBuilder(authToken, { operation: 'add', entries: finalMenusAdded }, 'PATCH');
          const url = 'https://ti-pei-gourmand.fr/api/menus';

          await fetchData(url, options);
          setIsMenuTreated(true);
        }
        // Le nouveau tableau initial vaut celui des produits affichés
        setInitialProductsDisplayed(productsDisplayed);

        /* ************ FIN TRAITEMENT DES AJOUTS / SUPP DES ÉLÉMENTS DU CAROUSEL ************ */
      }

      setIsLoading(false);
      setFormSubmitted(false);
    };

    if (firstResponse.status === 200) {
      submitSecondaryEntity();
    }
  }, [firstResponse]);

  useEffect(() => {
    const dishRemovedProcess = async () => {
      const options = optionsBuilder(authToken, { operation: 'remove', entries: finalDishesRemoved }, 'PATCH');
      const url = 'https://ti-pei-gourmand.fr/api/dishes';

      await fetchData(url, options);
      // Je vide le tableau des éléments ajoutés une fois le traitement achevé
      setFinalDishesRemoved([]);
      setIsDishTreated(false);
    };

    if ((isDishTreated) && (finalDishesRemoved.length > 0)) {
      dishRemovedProcess();
    }
  }, [isDishTreated]);

  useEffect(() => {
    const menuRemovedProcess = async () => {
      const options = optionsBuilder(authToken, { operation: 'remove', entries: finalMenusRemoved }, 'PATCH');
      const url = 'https://ti-pei-gourmand.fr/api/menus';

      await fetchData(url, options);
      // Je vide le tableau des éléments ajoutés une fois le traitement achevé
      setFinalMenusRemoved([]);
      setIsMenuTreated(false);
    };

    if ((isMenuTreated) && (finalMenusRemoved.length > 0)) {
      menuRemovedProcess();
    }
  }, [isMenuTreated]);

  useEffect(() => {
    // Méthode permettant la récupération de la liste des entités
    const fechDataAsync = async () => {
      const options = {
        method: 'GET',
        ...(name === 'restaurant_options' ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
      };

      // Requête l'API récupérant la liste des entités et construisant le cache de ces derniers
      const { data } = await fetchData(`https://ti-pei-gourmand.fr/api/${name}`, options);

      // Si aucune donnée n'est retournée
      if (!data) {
        // On retire le loading
        setIsLoading(false);

        // On ne fait rien de plus et on sort de la méthode
        return;
      }

      // Je récupère toutes les informations pertinentes concernant mes entrées
      setEntries(data['hydra:member'][0]);

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    };

    fechDataAsync();
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      {name === 'presentation_options' && entries && (
        <PresentationOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {name === 'about_us_options' && entries && (
        <AboutUsOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {name === 'special_options' && entries && (
        <SpecialOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries}
          setEntries={setEntries}
          setIsLoading={setIsLoading}
          productsDisplayed={productsDisplayed}
          setProductsDisplayed={setProductsDisplayed}
          initialProductsDisplayed={initialProductsDisplayed}
          setInitialProductsDisplayed={setInitialProductsDisplayed}
          setFinalDishesAdded={setFinalDishesAdded}
          setFinalDishesRemoved={setFinalDishesRemoved}
          setFinalMenusAdded={setFinalMenusAdded}
          setFinalMenusRemoved={setFinalMenusRemoved}
        />
      )}

      {name === 'menu_options' && entries && (
        <MenuOptionsFormPart
          handleSubmit={handleSubmit}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {name === 'team_options' && entries && (
        <TeamOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          setImageMemberInfos={setImageMemberInfos}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {name === 'gallery_options' && entries && (
        <GalleryOptionsFormPart
          handleSubmit={handleSubmit}
          setImageMemberInfos={setImageMemberInfos}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {name === 'footer_options' && entries && (
        <FooterOptionsFormPart
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {name === 'restaurant_options' && entries && (
        <RestaurantOptionsFormPart
          handleSubmit={handleSubmit}
          entries={entries}
          setEntries={setEntries}
        />
      )}
    </>
  );
}

GenericOption.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GenericOption;

import React from 'react';
import { differenceInMonths } from 'date-fns';

// Convertit le nom de mes colonnes en une version plus adapté à l'affichage
export const getLabelForColumn = (columnName) => {
  switch (columnName) {
    case 'username':
      return 'Pseudonyme';
    case 'phoneNumber':
      return 'Téléphone';
    case 'email':
      return 'E-mail';
    case 'hireDate':
      return 'Ancienneté';
    case 'roles':
      return 'Roles';
    case 'endDate':
      return 'Fin du contrat';
    case 'employmentStatus':
      return 'Type de contrat';
    case 'socialSecurityNumber':
      return 'N° Sécu';
    case 'comments':
      return 'Commentaires';

      /* -------------------------------------------------- */

    case 'quantity':
      return 'Stock restant';
    case 'percentQuantity':
      return '% restant';
    case 'category':
      return 'Catégorie';
    case 'isAllergen':
      return 'Allergène ?';
    case 'alert':
      return 'Alerte ?';
    case 'increaseMode':
      return 'Incrémentation ?';
    case 'startingDay':
      return ' 1ère incr.';
    case 'increaseInterval':
      return 'Incr. tous les';
    case 'isAdded':
      return 'Ajouter/Redéfinir';
    case 'quantityIncreased':
      return 'Redéfini à';

      /* -------------------------------------------------- */

    case 'price':
      return 'Prix (€)';
    case 'isSaled':
      return 'Vendu ?';
    case 'cookingChoice':
      return 'Cuisson ?';

      /* -------------------------------------------------- */

    case 'number':
      return 'Numéro';
    case 'isOccupied':
      return 'Table occupée ?';
    case 'urlIdentifier':
      return 'Identifiant URL';

      /* -------------------------------------------------- */
    default:
      return '';
  }
};

// Calcule le nombre de mois entre l'embauche et la date actuelle
const calculateMonthsOfService = (hireDate) => {
  const currentDate = new Date();
  const difference = differenceInMonths(currentDate, hireDate);

  return Number.isNaN(difference) ? '' : `${difference} mois`;
};

const getProperty = (object, path) => {
  const properties = path.split('.');
  return properties.reduce((value, prop) => (value ? value[prop] : undefined), object);
};

const getNamesSortedByProperty = (items, propertyName) => {
  if (items && items.length > 0) {
    return items
      .slice()
      .map((item) => (
        <li className="list-unstyled" key={item.id}>
          {getProperty(item, propertyName)}
        </li>
      ));
  }
  return [];
};

// Utilisation de la fonction utilitaire pour récupérer les noms des rôles d'un utilisateur
const getRolesName = (user) => getNamesSortedByProperty(user.userRoles, 'role.name');

// Permet de déterminer la valeur à renvoyer en cas de problème
const getDefaultIfUndefined = (value) => (
  (value !== undefined) && (value !== '') ? value : 'À définir'
);

function formatPercentage(value) {
  return value.endsWith('.00') ? parseInt(value, 10) : value;
}

export const getColumnValue = (entry, column) => {
  switch (column) {
    case 'username':
      return entry.username;
    case 'realName':
      return entry.realName;
    case 'phoneNumber':
      return entry.phoneNumber;
    case 'email':
      return entry.email;
    case 'hireDate':
      return getDefaultIfUndefined(calculateMonthsOfService(entry.hireDate));
    case 'roles':
      return getRolesName(entry);
    case 'endDate':
      return getDefaultIfUndefined(entry.endDate) !== 'À définir'
        ? new Date(entry.endDate).toLocaleDateString()
        : 'À définir';
    case 'employmentStatus':
      return getDefaultIfUndefined(entry.employmentStatus);
    case 'socialSecurityNumber':
      return getDefaultIfUndefined(entry.socialSecurityNumber);
    case 'comments':
      return getDefaultIfUndefined(entry.comments);

      /* -------------------------------------------------- */

    case 'title':
      return entry.title;
    case 'quantity':
      return `${entry.quantity}  ${entry.unit}`;
    case 'percentQuantity':
      return `${formatPercentage(((entry.quantity / entry.maxQuantity) * 100).toFixed(2))} %`;
    case 'category':
      return entry.category;
    case 'isAllergen':
      return entry.isAllergen ? 'Oui' : 'Non';
    case 'alert':
      return entry.alert ? 'Oui' : 'Non';
    case 'increaseMode':
      return entry.increaseMode ? 'Oui' : 'Non';
    case 'startingDay':
      return getDefaultIfUndefined(entry.startingDay);
    case 'increaseInterval':
      return getDefaultIfUndefined(entry.increaseInterval);
    case 'isAdded':
      return getDefaultIfUndefined(entry.isAdded) === true ? 'Ajouter' : 'Redéfinir';
    case 'quantityIncreased':
      return entry.isAdded ? `+ ${getDefaultIfUndefined(entry.quantityIncreased)}` : `à ${getDefaultIfUndefined(entry.quantityIncreased)}`;

      /* -------------------------------------------------- */

    case 'price':
      return entry.price;
    case 'isSaled':
      return entry.isSaled ? 'Oui' : 'Non';
    case 'cookingChoice':
      return entry.cookingChoice ? 'Oui' : 'Non';

      /* -------------------------------------------------- */

    case 'number':
      return entry.number;
    case 'isOccupied':
      return entry.isOccupied ? 'Oui' : 'Non';
    case 'urlIdentifier':
      return entry.urlIdentifier;

    default:
      return '';
  }
};

// Méthode permettant de changer la couleur du texte en fonction du pourcentage
export const getTextColorClass = (
  percentQuantity,
  stockWarningThreshold,
  stockCriticalThreshold,
) => {
  if (percentQuantity >= stockWarningThreshold) {
    return 'text-success';
  } if (percentQuantity > stockCriticalThreshold) {
    return 'text-warning';
  }
  return 'text-danger';
};

// Méthode permettant de récuperer le contenu du cache pour la ressource nommée en paramètre
export const getCacheContent = (name, cache) => {
  switch (name) {
    case 'users':
      return [...cache['https://ti-pei-gourmand.fr/api/users']['hydra:member']];
    case 'ingredients':
      return [...cache['https://ti-pei-gourmand.fr/api/ingredients']['hydra:member']];
    case 'dishes':
      return [...cache['https://ti-pei-gourmand.fr/api/dishes']['hydra:member']];
    case 'menus':
      return [...cache['https://ti-pei-gourmand.fr/api/menus']['hydra:member']];
    case 'tables':
      return [...cache['https://ti-pei-gourmand.fr/api/tables']['hydra:member']];
    default:
      return '';
  }
};

// Méthode permettant d'obtenir les informations nécessaires pour la mise à jour du cache trié
export const getUpdatedCacheContent = (name, cache, sortedData) => {
  switch (name) {
    case 'users':
      return {
        apiUrl: 'https://ti-pei-gourmand.fr/api/users',
        updatedCacheData: {
          ...cache['https://ti-pei-gourmand.fr/api/users'],
          'hydra:member': sortedData,
        },
      };
    case 'ingredients':
      return {
        apiUrl: 'https://ti-pei-gourmand.fr/api/ingredients',
        updatedCacheData: {
          ...cache['https://ti-pei-gourmand.fr/api/ingredients'],
          'hydra:member': sortedData,
        },
      };
    case 'dishes':
      return {
        apiUrl: 'https://ti-pei-gourmand.fr/api/dishes',
        updatedCacheData: {
          ...cache['https://ti-pei-gourmand.fr/api/dishes'],
          'hydra:member': sortedData,
        },
      };
    case 'menus':
      return {
        apiUrl: 'https://ti-pei-gourmand.fr/api/menus',
        updatedCacheData: {
          ...cache['https://ti-pei-gourmand.fr/api/menus'],
          'hydra:member': sortedData,
        },
      };
    case 'tables':
      return {
        apiUrl: 'https://ti-pei-gourmand.fr/api/tables',
        updatedCacheData: {
          ...cache['https://ti-pei-gourmand.fr/api/tables'],
          'hydra:member': sortedData,
        },
      };
    default:
      return '';
  }
};

// Méthode définissant les colonnes de base qui peuvent être triées
export const getSortParam = (entityName) => {
  switch (entityName) {
    case 'users':
      return 'realName';
    case 'ingredients':
      return 'title';
    case 'dishes':
      return 'title';
    case 'menus':
      return 'title';
    default:
      return 'realName';
  }
};

// Titres qu'il est possible d'attribuer à mes tableaux
export const titles = {
  users: 'Liste des utilisateurs',
  ingredients: 'Liste des ingrédients',
  dishes: 'Liste des plats',
  menus: 'Liste des menus',
  tables: 'Liste des tables',
};

import React, { useState } from 'react';
import GenericTable from '../../Generic/GenericTable';

function DishTable() {
  // State permettant de définir les informations des Plats à afficher
  const [displayedColumns, setDisplayedColumns] = useState({
    price: true,
    category: true,
    isSaled: true,
    cookingChoice: false,
  });

  return (
    <GenericTable
      name="dishes"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
      associatedName="ingredients"
    />
  );
}

export default DishTable;

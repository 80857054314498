// Méthode permettant en fonction de l'entité de construire le body de la requête
const getBodyContent = (name, selectedEntries) => {
  switch (name) {
    case 'users':
      return { usernames: selectedEntries };
    case 'ingredients':
      return { titles: selectedEntries };
    case 'dishes':
      return { titles: selectedEntries };
    case 'menus':
      return { titles: selectedEntries };
    case 'tables':
      return { numbers: selectedEntries };
    case 'orders':
      return { ids: selectedEntries };
    default:
      return '';
  }
};

export default getBodyContent;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function QRCode({
  qrCode,
  setQrCode,
}) {
  return (
    <Modal show onHide={() => setQrCode(null)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          QR Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          Imprimez puis présentez ce QR Code aux clients pour leur
          permettre de réserver pour la table associée.
        </p>
        <p>
          <img src={qrCode} alt="QR Code" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center w-100">
          <Button variant="primary" size="sm" onClick={() => setQrCode(null)}>
            Fermer
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

QRCode.propTypes = {
  qrCode: PropTypes.string.isRequired,
  setQrCode: PropTypes.func.isRequired,
};

export default QRCode;

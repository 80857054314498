import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

function TypingAnimation({ terms, animationSpeed }) {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);
  const termsArray = terms.split(' ');

  // Vitesse pour la frappe et le retrait
  const typingSpeed = animationSpeed;

  // Effet pour simuler la frappe et l'effacement des termes
  useEffect(() => {
    if (index === termsArray.length) {
      setIndex(0); // Réinitialiser l'index pour boucler sur les termes
      setReverse(false);
    }

    if (subIndex === termsArray[index].length + 1 && !reverse) {
      setBlink(false);
      setTimeout(() => setBlink(true), 500);
      setTimeout(() => {
        setReverse(true);
      }, 200);
    } else if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prevIndex) => (prevIndex + 1) % termsArray.length);
    }

    const timeout = setTimeout(() => {
      setSubIndex((prevSubIndex) => prevSubIndex + (reverse ? -1 : 1));
    }, typingSpeed);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse, termsArray]);

  return (
    <>
      <span className="typer">
        {termsArray[index].substring(0, subIndex)}
      </span>
      <span className="cursor" style={{ opacity: blink ? 1 : 0 }}>_</span>
    </>
  );
}

TypingAnimation.propTypes = {
  terms: PropTypes.string.isRequired,
  animationSpeed: PropTypes.number.isRequired,
};

export default TypingAnimation;

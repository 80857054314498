// Méthode renvoyant l'objet en paramètre avec seulement certaines propriétés
export const getDefaultEntryColumns = (name) => {
  switch (name) {
    case 'presentation_options':
      return {
        title: '',
        subtitle: '',
        terms: '',
        buttonText: '',
        animationSpeed: 0,
        description: '',
        logo: '',
        backgroundImg: '',
      };
    case 'about_us_options':
      return {
        isEnabled: true,
        title: '',
        subtitle: '',
        description: '',
        mainImg: '',
        secondaryImg: '',
      };
    case 'special_options':
      return {
        isEnabled: true,
        title: '',
        description: '',
        backgroundImg: '',
      };
    case 'menu_options':
      return {
        title: '',
        description: '',
      };
    case 'team_options':
      return {
        isEnabled: true,
        title: '',
        description: '',
        backgroundImg: '',
        teamMembers: [
          {
            name: '',
            presentation: '',
            facebookLink: '',
            twitterLink: '',
            linkedinLink: '',
            imageName: '',
          },
        ],
      };
    case 'gallery_options':
      return {
        isEnabled: true,
        title: '',
        description: '',
        galleryImages: [
          {
            description: '',
            imageName: '',
          },
        ],
      };
    case 'footer_options':
      return {
        description: '',
        facebookLink: '',
        twitterLink: '',
        googleplusLink: '',
        pinterestLink: '',
        linkedinLink: '',
        address: '',
        phoneNumber: '',
        mail: '',
        firstOpeningDays: '',
        firstOpeningHours: '',
        secondaryOpeningDays: '',
        secondaryOpeningHours: '',
        copyright: '',
      };
    case 'restaurant_options':
      return {
        openingHours: [
          {
            days: '',
            hours: '',
          },
        ],
        stockWarningThreshold: 0,
        stockCriticalThreshold: 0,
        averageWaitTime: 0,
        longWaitTime: 0,
        refreshInterval: 0,
        totalOrderAim: 0,
        totalMonthOrderAim: 0,
        profitAim: 0,
        monthProfitAim: 0,
      };
    default:
      return {
      };
  }
};

// Permet de préparer le body de la requête pour l'envoi d'images
export const getImageBodyForm = (formData, imageInfos, name = null) => {
  // Pour chaque image sélectionnée, on l'ajoute à notre objet qui sera envoyé au server
  Object.keys(imageInfos).forEach((fieldName, index) => {
    const fileInfo = imageInfos[fieldName];
    formData.append(fieldName, fileInfo.imageFile);
    formData.append(fieldName, fileInfo.imageName);

    // Dans le cas de l'envoi des images de l'entité associée
    formData.append(`${name === 'team_options' ? 'teamMembers' : 'galleryImages'}[${index}]`, name === 'team_options' ? fileInfo.memberId : fileInfo.imageId);
  });
};

export const getBodyData = (name, entries) => {
  switch (name) {
    case 'team_options':
      return entries.teamMembers;
    case 'gallery_options':
      return entries.galleryImages;
    default:
      return {};
  }
};

export const getUrl = (name) => {
  switch (name) {
    case 'team_options':
      return 'https://ti-pei-gourmand.fr/api/team_members';
    case 'gallery_options':
      return 'https://ti-pei-gourmand.fr/api/gallery_images';
    default:
      return {};
  }
};

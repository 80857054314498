import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import SpecialCarousel from '../SpecialCarousel';

function Special({ specialOptions, carouselDatas }) {
  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="wow fadeIn">
            <h2 className="block-title color-white text-center">{specialOptions.title}</h2>
            <h5 className="title-caption text-center">{specialOptions.description}</h5>
          </div>
          <SpecialCarousel carouselDatas={carouselDatas} />
        </Col>
      </Row>
    </Container>
  );
}

Special.propTypes = {
  specialOptions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  carouselDatas: PropTypes.arrayOf(
    PropTypes.shape({

    }),
  ).isRequired,
};

export default Special;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import useRestaurantRequest from '../../../utils/hooks/client';

const ConfirmationContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: #202020;
  color: white !important;
  text-align: center;
  z-index: 999;
  padding: 50px;
`;

const ConfirmationTitle = styled.h2`
  color: white !important;
`;

function Confirmation({ confirmation, orders, tableNumber }) {
  const [counter, setCounter] = useState(5);
  const { fetchRestaurantData } = useRestaurantRequest();

  useEffect(() => {
    if (confirmation.display) {
      const sendOrderAsync = async () => {
        const body = {
          orders: orders.map((order) => {
            // Quand mon order contient associatedDishes on a un menu avec un tableau de plats
            if (order.associatedDishes) {
              // On retourne
              return {
                // Le nom du menu
                title: order.title,
                // Les informations de sur les plats du menu commandé
                associatedDishes: order.associatedDishes.map((associatedDish) => ({
                  id: associatedDish.id,
                  quantity: associatedDish.quantity,
                  ingredientsToRemove: associatedDish.ingredientsToRemove,
                  selectedMeat: associatedDish.selectedMeat,
                })),
              };
            }
            // Dans le cas contraire il s'agit d'un plat avec ses informations
            return {
              id: order.id,
              quantity: order.quantity,
              ingredientsToRemove: order.ingredientsToRemove,
              selectedMeat: order.selectedMeat,
            };
          }),
          // Je rajoute le prix total de la commande
          totalPrice: confirmation.totalPrice,
        };

        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/ld+json',
          },
          body: JSON.stringify(body),
        };

        const url = `https://ti-pei-gourmand.fr/api/orders/${tableNumber}`;

        const { response } = await fetchRestaurantData(url, options);

        if (response.ok) {
          const authToken = localStorage.getItem('authToken');

          // Si c'est un employé qui est à l'origine de la commande
          if (authToken) {
            // Je le redirige vers l'interface d'admin
            window.location.href = '/admin/service';
          // Si c'est un client vers la page par défaut
          } else {
            const interval = setInterval(() => {
              setCounter((prevCounter) => {
                if (prevCounter <= 1) {
                  clearInterval(interval);
                  window.location.href = '/restaurant';
                  return 0;
                }
                return prevCounter - 1;
              });
            }, 1000);
            return () => clearInterval(interval);
          }
        }
        return false;
      };

      sendOrderAsync();
    }
  }, [confirmation]);

  return (
    <ConfirmationContainer>
      <ConfirmationTitle>
        Votre commande a été validée ! Merci !
        <br />
        <br />
        Redirection dans :
        {' '}
        {counter}
        {' '}
        s
      </ConfirmationTitle>
    </ConfirmationContainer>
  );
}

Confirmation.propTypes = {
  confirmation: PropTypes.shape({
    display: PropTypes.bool,
    totalPrice: PropTypes.number,
  }).isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  tableNumber: PropTypes.number.isRequired,
};

export default Confirmation;

import {
  React, createContext, useEffect, useMemo, useState,
} from 'react';
import { PropTypes } from 'prop-types';
// eslint-disable-next-line import/no-cycle
import {
  useApiRequest, useAssociativeEntityUpdater, useAuth, useCache, useModal,
} from '../hooks/admin';
import useRestaurantRequest from '../hooks/client';
import SpinnerWrapper from '../../composants/Admin/SpinnerWrapper';

const loadCSS = (url) => new Promise((resolve, reject) => {
  const link = document.createElement('link');
  link.href = url;

  // l'id permettra de pouvoir changer dynamiquement la feuille des couleurs
  if (url === '/RestaurantTheme/css/colors/orange.css') {
    link.id = 'changeable-colors';
  }
  link.rel = 'stylesheet';
  link.onload = () => resolve();
  link.onerror = () => reject();
  document.head.appendChild(link);
});

// Définissez les importations des fichiers CSS spécifiques à chaque thème
const themeImports = {
  login: {
    main: () => [
      '/DarkPanTheme/bootstrap.min.css',
      '/DarkPanTheme/templateStyle.css',
      '/DarkPanTheme/font.css',
    ],
  },
  admin: {
    main: () => [
      '/DarkPanTheme/bootstrap.min.css',
      '/DarkPanTheme/templateStyle.css',
      '/DarkPanTheme/font.css',
    ],
  },
  restaurant: {
    main: () => [
      '/RestaurantTheme/css/bootstrap.min.css',
      '/RestaurantTheme/css/style.css',
      '/RestaurantTheme/css/responsive.css',
      '/RestaurantTheme/css/colors/orange.css',
    ],
  },
};

// NOTES CONCERNANT LES HOOK ET LE CONTEXTE
/*
  Les hooks qui gèrent un état interne et qui doivent être accessibles globalement
  dans l'application devraient être partagés via le contexte. Cela permet de garantir
  que l'état est cohérent et synchronisé à travers tous les composants consommateurs.

  Tous les autres hooks, qui ne nécessitent pas un état partagé ou qui sont utilisés
  de manière indépendante par les composants, devraient simplement être importés
  et utilisés directement sans passer par le contexte.
*/

export const ApiContext = createContext();

// Permet de partager mes Hooks et States entre mes composants
export function ApiProvider({ children }) {
  // State permettant de gérer l'affichage du loading
  const [isLoading, setIsLoading] = useState(true);

  // Hook permettant la construction du cache
  const { cache, updateCache, resetCache } = useCache();

  /*
    Hook permettant l'appel à l'API et se servant du hook du cache pour
    construire ce dernier une fois la réponse obtenue
  */
  const { errors, setErrors, fetchData } = useApiRequest(cache, updateCache);

  /*
    Hook permettant la mise à jour des informations du User connecté en
    se servant du cache qu'il devra parfois mettre à jour grâce au hook d'appel API
  */
  const {
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
  } = useAuth(setIsLoading, cache, fetchData);

  /*
     Hook permettant de requêter l'API et d'enregistrer ou de supprimer des
    associations d'entités entraînant parfois la mise à jour des informations du User connecté
  */
  const { isArraySuperset, updateAssociativeEntity } = useAssociativeEntityUpdater(
    fetchData,
    authToken,
    updateUserAuth,
  );

  const contextValue = useMemo(() => ({
    isLoading,
    setIsLoading,
    cache,
    updateCache,
    resetCache,
    errors,
    setErrors,
    fetchData,
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
    isArraySuperset,
    updateAssociativeEntity,
  }), [
    isLoading,
    cache,
    errors,
    authToken,
    authRoles,
    authId,
    authRoles,
    authUser,
    authImg,
    authPermissions,
  ]);
  console.log('cache : ', cache);
  return (
    <ApiContext.Provider value={contextValue}>
      <SpinnerWrapper $showSpinner={isLoading} />
      {children}
    </ApiContext.Provider>
  );
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/* ----------------------------------------------------- */

export const RestaurantContext = createContext();

export function RestaurantProvider({ children }) {
  // Hook chargé de requêter très simplement l'api sans authentification
  const { fetchRestaurantData } = useRestaurantRequest();

  const contextValue = useMemo(() => ({
    fetchRestaurantData,
  }), []);

  return (
    <RestaurantContext.Provider value={contextValue}>
      {children}
    </RestaurantContext.Provider>
  );
}

RestaurantProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/* ----------------------------------------------------- */

export const ModalManagementContext = createContext();

export function ModalManagementProvider({ children }) {
  // Hook chargé de gérer l'état des modales
  const { handleSuccessInModal } = useModal();

  const contextValue = useMemo(() => ({
    handleSuccessInModal,
  }), [handleSuccessInModal]);

  return (
    <ModalManagementContext.Provider value={contextValue}>
      {children}
    </ModalManagementContext.Provider>
  );
}

ModalManagementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/* ----------------------------------------------------- */

export const ThemeContext = createContext();

export function ThemeProvider({ children, theme }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { main: themeImport } = themeImports[theme];
    Promise.all(themeImport().map(loadCSS)).then(() => {
      setLoading(false);
    });
  }, [theme]);

  if (loading) {
    return <div />;
  }

  return (
    <ThemeContext.Provider>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
};

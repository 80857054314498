import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const ToAdminButton = styled(Button)`
  position: fixed;
  top: 94px;
  height: 50px;
  left: 0;
  z-index: 999;
`;

function ToAdmin() {
  const handleClick = () => {
    window.location.href = '/admin';
  };

  return (
    <ToAdminButton
      variant="success"
      size="sm"
      onClick={handleClick}
    >
      Retour à l&apos;administration
    </ToAdminButton>
  );
}

export default ToAdmin;

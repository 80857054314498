/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-scroll';

function Header({ logo }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const onSetActive = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const headerHeight = document.getElementById('header').offsetHeight;

      if (offset > headerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="site-header">
      <header id="header" className={`header-block-top ${isScrolled ? 'fixed-menu' : ''}`}>
        <Container>
          <Row>
            <Col>
              <div className="main-menu">
                <nav className="navbar navbar-default" id="mainNav">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className={`navbar-toggle ${isNavCollapsed ? 'collapsed' : ''}`}
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded={!isNavCollapsed}
                      aria-controls="navbar"
                      onClick={handleNavCollapse}
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <div className="logo">
                      <a className="navbar-brand js-scroll-trigger logo-header" href="#">
                        <img src={`https://ti-pei-gourmand.fr/images/presentationOptions/${logo}`} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`} id="navbar">
                    <ul className="nav navbar-nav navbar-right">
                      <li className={`${activeLink === 'banner' ? 'active' : ''}`}><Link spy smooth offset={-70} duration={500} to="banner" onSetActive={() => onSetActive('banner')}>Accueil</Link></li>
                      <li className={`${activeLink === 'about' ? 'active' : ''}`}><Link spy smooth offset={-70} duration={500} to="about" onSetActive={() => onSetActive('about')}>À propos</Link></li>
                      <li className={`${activeLink === 'menu' ? 'active' : ''}`}><Link spy smooth offset={-70} duration={500} to="menu" onSetActive={() => onSetActive('menu')}>Menu</Link></li>
                      <li className={`${activeLink === 'our_team' ? 'active' : ''}`}><Link spy smooth offset={-70} duration={500} to="our_team" onSetActive={() => onSetActive('our_team')}>Team</Link></li>
                      <li className={`${activeLink === 'gallery' ? 'active' : ''}`}><Link spy smooth offset={-70} duration={500} to="gallery" onSetActive={() => onSetActive('gallery')}>Galerie</Link></li>
                    </ul>
                  </div>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}

Header.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Header;

import React, { useState } from 'react';
import GenericTable from '../../Generic/GenericTable';

function MenuTable() {
  // State permettant de définir les informations des Menus à afficher
  const [displayedColumns, setDisplayedColumns] = useState({
    price: true,
    isSaled: true,
  });

  return (
    <GenericTable
      name="menus"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
      associatedName="dishes"
    />
  );
}

export default MenuTable;

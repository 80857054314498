/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../composants/Admin/Panel/SideBar';
import MainPanel from '../../composants/Admin/Panel/MainPanel';
import UserTable from '../../composants/Admin/UsersManagement/UserTable';
import RoleManagement from '../../composants/Admin/RolesManagement';
import StockTable from '../../composants/Admin/StocksManagement/StockTable';
import DishTable from '../../composants/Admin/DishManagement/DishTable';
import MenuTable from '../../composants/Admin/MenuManagement/MenuTable';
import ThemeManagement from '../../composants/Admin/ThemeManagement';
import TableList from '../../composants/Admin/TableManagement/TableList';
import RestaurantManagement from '../../composants/Admin/RestaurantManagement';
import OrdersManagement from '../../composants/Admin/OrdersManagement';
import StocksAlert from '../../composants/Admin/StocksAlert';
import { useApi } from '../../utils/hooks/admin';
import Charts from '../../composants/Admin/Charts';
import OrderListManagement from '../../composants/Admin/OrderListManagement';
import Planning from '../../composants/Admin/Planning';

function Admin() {
  // State récupérant le texte de l'option sélectionnée dans la sidebar
  const [selectedOption, setSelectedOption] = useState('');

  const { authPermissions } = useApi();
  const { mode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Méthode déclenchée au clic sur une des options du menu de la sidebar
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  // Méthode permettant de rendre un composant en fonction de l'option cliquée de la sidebar
  const renderMainPanel = () => {
    switch (selectedOption) {
      case 'Gestion des utilisateurs':
        return <UserTable />;
      case 'Gestion des roles':
        return <RoleManagement />;
      case 'Gestion des stocks':
        return <StockTable />;
      case 'Liste des plats':
        return <DishTable />;
      case 'Liste des menus':
        return <MenuTable />;
      case 'Préparation':
        return <OrdersManagement mode="Préparation" />;
      case 'Service':
        return <OrdersManagement mode="Service" />;
      case 'Paiement':
        return <OrdersManagement mode="Caisse" />;
      case 'Liste commandes':
        return <OrderListManagement />;
      case 'Informations des tables':
        return <TableList />;
      case 'Personnalisation':
        return <ThemeManagement />;
      case 'Options générales':
        return <RestaurantManagement />;
      case 'Graphiques':
        return <Charts />;
      case 'Planning':
        return <Planning />;
      default:
        return <Charts />;
    }
  };

  useEffect(() => {
    if (selectedOption === 'Page client') {
      window.location.href = '/restaurant';
    } else if (mode === 'service') {
      setSelectedOption('Service');

      // Met l'url à jour en retirant le /service évitant ainsi de rester bloquer dans ce cas
      const newUrl = location.pathname.replace(`/${mode}`, '');
      navigate(newUrl, { replace: true });
    }
  }, [selectedOption]);

  return (
    <Container fluid className="position-relative d-flex p-0">
      <Sidebar handleOptionClick={handleOptionClick} />
      <MainPanel>
        {renderMainPanel()}
        {authPermissions.includes('Alerte de stock') && (
          <StocksAlert />
        )}
      </MainPanel>
    </Container>
  );
}

export default Admin;

/*
  Permet de retourner la date actuelle à transmettre au champ hireDate afin que ce dernier
  ne puisse pas accepter des valeurs ultérieures à cette dernière
*/
const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  // Les mois commencent à 0, d'où le + 1
  let month = currentDate.getMonth() + 1;

  let day = currentDate.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

export default getCurrentDate;

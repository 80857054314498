import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  pointer-events: none;
`;

function Spinner({ $showSpinner }) {
  return (
    <LoaderContainer id="loader" $visible={$showSpinner}>
      <div id="status" />
    </LoaderContainer>
  );
}

Spinner.propTypes = {
  $showSpinner: PropTypes.bool.isRequired,
};

export default Spinner;

import React, { useState } from 'react';
import GenericTable from '../../Generic/GenericTable';

function StockTable() {
  // State permettant de définir les informations des Ingrédients à afficher
  const [displayedColumns, setDisplayedColumns] = useState({
    quantity: true,
    percentQuantity: true,
    category: true,
    isAllergen: false,
    alert: false,
    increaseMode: false,
    startingDay: false,
    increaseInterval: false,
    isAdded: false,
    quantityIncreased: false,
  });

  return (
    <GenericTable
      name="ingredients"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
    />
  );
}

export default StockTable;

const getUserRoles = (user) => {
  if (user && user.userRoles && user.userRoles.length > 0) {
    return user.userRoles.map((userRole) => userRole.role.id);
  }
  return [];
};

const getDishIngredients = (dish) => {
  if (dish && dish.dishIngredients && dish.dishIngredients.length > 0) {
    return dish.dishIngredients.map((dishIngredient) => ({
      id: dishIngredient.ingredient.id,
      quantityNeeded: dishIngredient.quantityNeeded,
      isRemovable: dishIngredient.isRemovable,
    }));
  }
  return [];
};

const getMenuDishes = (menu) => {
  if (menu && menu.menuDishes && menu.menuDishes.length > 0) {
    return menu.menuDishes.map((menuDish) => menuDish.dish.id);
  }
  return [];
};

// Convertit la String de date de la bdd en Date pour la valeur par défaut du champ date
const stringToDate = (date) => {
  // On crée un objet Date avec notre String de date
  const formattedDate = new Date(date);

  // On en déduit l'année
  const year = formattedDate.getFullYear();

  // Les mois commencent à 0, d'où le + 1. On en déduit le mois
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');

  // On en déduit le jour
  const day = formattedDate.getDate().toString().padStart(2, '0');

  // on retourne la date convertit en Date
  return `${year}-${month}-${day}`;
};

// Méthode renvoyant l'objet en paramètre avec seulement certaines propriétés
export const getEntryColumns = (name, selectedEntry) => {
  switch (name) {
    case 'users':
      return {
        username: selectedEntry ? selectedEntry.username : '',
        realName: selectedEntry ? selectedEntry.realName : '',
        password: '',
        email: selectedEntry ? selectedEntry.email : '',
        phoneNumber: selectedEntry ? selectedEntry.phoneNumber : '',
        employmentStatus: selectedEntry ? selectedEntry.employmentStatus : '',
        socialSecurityNumber: selectedEntry ? selectedEntry.socialSecurityNumber : '',
        comments: selectedEntry ? selectedEntry.comments : '',
        hireDate: selectedEntry ? stringToDate(selectedEntry.hireDate) : '',
        endDate: selectedEntry ? stringToDate(selectedEntry.endDate) : '',
      };
    case 'dishes':
      return {
        title: selectedEntry ? selectedEntry.title : '',
        description: selectedEntry ? selectedEntry.description : '',
        price: selectedEntry ? selectedEntry.price : 0,
        category: selectedEntry ? selectedEntry.category : '',
        isSaled: selectedEntry ? selectedEntry.isSaled : false,
        cookingChoice: selectedEntry ? selectedEntry.cookingChoice : false,
      };
    case 'menus':
      return {
        title: selectedEntry ? selectedEntry.title : '',
        description: selectedEntry ? selectedEntry.description : '',
        price: selectedEntry ? selectedEntry.price : 0,
        isSaled: selectedEntry ? selectedEntry.isSaled : false,
      };
    case 'ingredients':
      return {
        title: selectedEntry?.title || '',
        quantity: selectedEntry?.quantity || 0,
        maxQuantity: selectedEntry?.maxQuantity || 0,
        unit: selectedEntry?.unit || '',
        category: selectedEntry?.category || '',
        isAllergen: selectedEntry?.isAllergen || false,
        alert: selectedEntry?.alert || false,
        increaseMode: selectedEntry?.increaseMode || false,
        startingDay: selectedEntry?.startingDay || '',
        increaseInterval: selectedEntry?.increaseInterval || 0,
        isAdded: selectedEntry?.isAdded || false,
        quantityIncreased: selectedEntry?.quantityIncreased || 0,
      };
    case 'tables':
      return {
        number: selectedEntry?.orderNumber || 0,
        urlIdentifier: selectedEntry?.urlIdentifier || '',
      };
    case 'planning':
      return {};
    default:
      return '';
  }
};

// Méthode renvoyant l'ensemble des ids d'entités secondaires initialement attribuées
export const getInitialAssociatedEntitiesIds = (name, selectedEntry) => {
  switch (name) {
    case 'users':
      return getUserRoles(selectedEntry);
    case 'dishes':
      return getDishIngredients(selectedEntry);
    case 'menus':
      return getMenuDishes(selectedEntry);
    default:
      return '';
  }
};

// Méthode renvoyant une partie du corps de la requête
export const getBodyData = (name, selectedEntryColumns, isAddModal) => {
  switch (name) {
    case 'users':
      return {
        username: selectedEntryColumns.username,
        realName: selectedEntryColumns.realName,
        phoneNumber: selectedEntryColumns.phoneNumber,
        email: selectedEntryColumns.email,
        hireDate: (
          selectedEntryColumns.hireDate === '' || selectedEntryColumns.hireDate === 'NaN-NaN-NaN'
        ) ? null : selectedEntryColumns.hireDate,
        endDate: (
          selectedEntryColumns.endDate === '' || selectedEntryColumns.endDate === 'NaN-NaN-NaN'
        ) ? null : selectedEntryColumns.endDate,
        employmentStatus: selectedEntryColumns.employmentStatus,
        socialSecurityNumber: selectedEntryColumns.socialSecurityNumber,
        comments: selectedEntryColumns.comments,

        // Ajoute plainPassword seulement si selectedEntryColumns.password n'est pas vide
        ...(selectedEntryColumns.password && { password: selectedEntryColumns.password }),

        // Ajoute hasImage seulement si la méthode est POST
        hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
      };
    case 'dishes':
      return {
        title: selectedEntryColumns.title,
        description: selectedEntryColumns.description,
        category: selectedEntryColumns.category,
        price: selectedEntryColumns.price,
        isSaled: selectedEntryColumns.isSaled,
        isAllergen: selectedEntryColumns.isAllergen,
        cookingChoice: selectedEntryColumns.cookingChoice,

        // Ajoute hasImage seulement si la méthode est POST
        hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
      };
    case 'menus':
      return {
        title: selectedEntryColumns.title,
        description: selectedEntryColumns.description,
        price: selectedEntryColumns.price,
        isSaled: selectedEntryColumns.isSaled,

        // Ajoute hasImage seulement si la méthode est POST
        hasImage: !isAddModal ? !!selectedEntryColumns.imageFile : undefined,
      };
    default:
      return '';
  }
};

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import {
  ApiContext,
  ModalManagementContext,
  RestaurantContext,
  ThemeContext,
} from '../../context';
import methodSelection from '../../helpers/cache';
import errorMessages from '../../errors';

// Hook permettant de requêter l'API
export const useApiRequest = (cache, updateCache) => {
  // State contenant les messages d'erreur renvoyés par l'API
  const [errors, setErrors] = useState([]);

  // Permet la redirection
  const navigate = useNavigate();

  // Mise à jour du state des erreurs
  const updateErrors = (data) => {
    // Initialisation du tableau des erreurs
    let errorsArr = [];

    // Vérification de la présence de data.error ou data.errors
    if (data.error) {
      errorsArr.push(data.error);
    } else if (data.errors) {
      errorsArr = data.errors;
    } else if (data.violations && Array.isArray(data.violations)) {
    // Si aucun error ou errors, vérification de la présence de data.violations
    // et construction du tableau des messages de violation
      errorsArr = data.violations.map((violation) => violation.message);
    }

    // Mise à jour du state avec les erreurs
    setErrors(errorsArr);
  };

  // Méthode requêtant l'API, modifiant le cache et renvoyant la réponse
  // Multiple indique un traitement de plusieurs entrées à la fois
  const fetchData = async (url, options, isLogin = false) => {
    // Si j'effectue une requête GET mais que le cache contient déjà les informations associées
    if (options.method === 'GET' && cache[url]) {
      // Je retourne ces dernières afin de ne pas interroger le serveur pour rien
      return { data: cache[url] };
    }

    // On récupére la réponse renvoyée par le serveur
    const response = await fetch(url, options);

    // 204 représente la suppression sans réponse en json à traiter. On s'arrête là
    if (response.status === 204) {
      return { data: '', response };
    }
    // On récupère les données associées à cette réponse
    const data = await response.json();

    // Si la réponse indique que la requête a échoué
    if (!response.ok) {
      // Si je ne suis pas actuellement sur la page de Login
      if (!isLogin) {
        // Si le code de cette réponse correspond à l'expiration du token
        if (response.status === 401) {
          // Je redirige le User vers le Login en lui indiquant que son token a expiré
          navigate(
            '/login',
            { state: { errorMessage: 'Votre session a expiré. Veuillez vous reconnecter.' } },
          );
        }

        updateErrors(data);

        // Je renvoie des données génériques au composant ayant fait l'appel au hook
        return { data: '', response: '' };
      }
      // Si je sur la page Login, on détermine le type d'erreur à afficher
      const errorMessage = response.status === 401
        ? errorMessages.invalidCredentials
        : errorMessages.serverError;

      // Je mets à jour mon state dédié aux erreurs
      setErrors([errorMessage]);
    }

    // Construit et retourne le contenu du cache pour la ressource manipulée par le serveur
    return methodSelection(url, data, response, cache, updateCache, options);
  };

  return { errors, setErrors, fetchData };
};

// Hook permettant de gérer le comportement de mes modales
export const useModal = () => {
  // Définit le comportement de mes modales au moment de la validation de ces dernières
  const handleSuccessInModal = (response, handleClose, handleSuccess, setIsLoading) => {
    /*
      Si la réponse liée à l'opération associée à la validation de la modale
      indique que la requête a réussi
    */
    if (response && response.ok) {
      // Je ferme cette dernière
      handleClose();

      // Le traitement terminé, je retire le loading
      setIsLoading(false);

      // Je recharge le parent
      handleSuccess();
    }
  };
  return { handleSuccessInModal };
};

// Hook permettant de requêter l'API et d'enregistrer ou de supprimer des associations d'entités
export const useAssociativeEntityUpdater = (fetchData, authToken, updateUserAuth) => {
  // Méthode indiquant si deux tableaux contiennent les mêmes éléments
  const isArraySuperset = (initialsecondEntities, secondEntities) => initialsecondEntities.every(
    (initialElem) => secondEntities.includes(initialElem),
  );

  // Méthode indiquant si deux tableaux d'objets contiennent les mêmes éléments
  const isArrayOfObjectSuperset = (initialsecondEntities, secondEntities) => secondEntities.every(
    (elem) => initialsecondEntities.some(
      (initialElem) => elem.id === initialElem.id
      && elem.quantityNeeded === initialElem.quantityNeeded
      && elem.isRemovable === initialElem.isRemovable,
    ),
  );

  // Méthode permettant de requêter l'API et d'ajouter ou de supprimer des associations d'entités
  const updateAssociativeEntity = async (
    associativeEntityName,
    firstEntityId,
    secondEntities,
    initialsecondEntities,
    name,
  ) => {
    let entitiesToAdd = [];
    let entitiesToRemove = [];

    if (name === 'users' || name === 'roles' || name === 'menus') {
      // Détermine les entités ajoutés (dans secondEntityIds mais pas dans initialsecondEntity)
      if (!isArraySuperset(
        initialsecondEntities,
        secondEntities,
      )
      || !isArraySuperset(secondEntities, initialsecondEntities)) {
        entitiesToAdd = secondEntities.filter(
          (secondEntityId) => !initialsecondEntities.includes(secondEntityId),
        );

        // Détermine les entités retirés (dans initialsecondEntity mais pas dans secondEntityIds)
        entitiesToRemove = initialsecondEntities.filter(
          (initialsecondEntityId) => !secondEntities.includes(initialsecondEntityId),
        );
      }
    } else if (name === 'dishes') {
      console.log('initialsecondEntities : ', initialsecondEntities);
      console.log('secondEntities : ', secondEntities);
      // Si le tableau de départ est différent du tableau final
      if (!isArrayOfObjectSuperset(
        initialsecondEntities,
        secondEntities,
      )
      || !isArrayOfObjectSuperset(secondEntities, initialsecondEntities)) {
        // Détermine les entités ajoutées (dans secondEntities mais pas dans initialsecondEntities)
        entitiesToAdd = secondEntities.filter(
          (secondEntity) => !initialsecondEntities.some(
            (initialEntity) => initialEntity.id === secondEntity.id
            && initialEntity.quantityNeeded === secondEntity.quantityNeeded
            && initialEntity.isRemovable === secondEntity.isRemovable,
          ),
        );

        // Détermine les entités retirées (dans initialsecondEntities mais pas dans secondEntities)
        entitiesToRemove = initialsecondEntities.filter(
          (initialEntity) => !secondEntities.some(
            (secondEntity) => secondEntity.id === initialEntity.id,
          ),
        );
      }
    }

    // Si il y a de nouvelles associations d'entités à ajouter
    if (entitiesToAdd.length > 0) {
      const userRolePostOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToAdd],
          isLastMethod: name === 'users' ? entitiesToRemove.length === 0 : false,
        }),
      };

      // On requête l'API pour inscrire la ou les nouvelle(s) association(s) d'entités
      const { data: roleAddData } = await fetchData(
        `https://ti-pei-gourmand.fr/api/${associativeEntityName}`,
        userRolePostOptions,
      );

      // Si l'API a fourni un nouveau token d'id
      if (roleAddData?.token) {
        // On met à jour nos informations relatives au User connecté
        updateUserAuth(roleAddData.token);
      }
    }

    // Si des associations d'entités sont à supprimer
    if (entitiesToRemove.length > 0) {
      const userRoleDeleteOptions = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/ld+json',
        },
        body: JSON.stringify({
          firstEntityId,
          secondEntityIds: [...entitiesToRemove],
        }),
      };

      // On requête l'API pour retirer la ou les ancienne(s) association(s) d'entités
      const { data: roleDeleteData } = await fetchData(
        `https://ti-pei-gourmand.fr/api/${associativeEntityName}`,
        userRoleDeleteOptions,
      );

      // Si l'API a fourni un nouveau token d'id
      if (roleDeleteData?.token) {
        // On met à jour nos informations relatives au User connecté
        updateUserAuth(roleDeleteData.token);
      }
    }
  };
  return { isArraySuperset, updateAssociativeEntity };
};

// Hook permettant la gestion du cache
export const useCache = () => {
  // State contenant les informations de mes entités
  const [cache, setCache] = useState({});

  /*
    Méthode permettant de mettre à jour, à partir de la réponse du serveur,
    les informations pour mes entités identifiées par l'url
  */
  const updateCache = (url, data) => {
    setCache((prevCache) => ({
      ...prevCache,
      [url]: data,
    }));
  };

  const resetCache = () => {
    setCache({});
  };

  return { cache, updateCache, resetCache };
};

export const useAuth = (setIsLoading, cache, fetchData) => {
  // States contenant les informations relatives au User connecté
  const [authToken, setAuthToken] = useState('');
  const [authId, setAuthId] = useState(0);
  const [authUser, setAuthUser] = useState('');
  const [authImg, setAuthImg] = useState('default_user_image.png');
  const [authRoles, setAuthRoles] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);

  // Permet la redirection
  const navigate = useNavigate();

  // Méthode permettant la mise à jour des informations relatives au User connecté
  const updateUserAuth = async (
    initialAuthToken,
    initialId = 0,
    initialAuthRoles = [],
    initialAuthUser = null,
    isRetrievedFromLS = false,
  ) => {
    const token = initialAuthToken;
    let id = initialId;
    let roles = [...initialAuthRoles];
    let user = initialAuthUser;

    const rolesOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    /*
      Requête l'API et entraîne la construction du cache des rôles
      Ce cache est nécessaire pour obtenir les permissions accordées au User connecté
    */
    await fetchData('https://ti-pei-gourmand.fr/api/roles', rolesOptions);

    // Si on ne possède pas les informations sur le User connecté
    if (roles.length === 0 && !user) {
      // Si le token n'est pas enregistré dans le LocalStorage
      if (!isRetrievedFromLS) {
        // On stocke ce dernier
        localStorage.setItem('authToken', token);
      }

      // À partir de ce token on peut en déduire les informations associées
      const decodedToken = jwtDecode(token);

      id = decodedToken.id;

      // Username associé au token
      user = decodedToken.username;

      // Roles associés au token
      roles = decodedToken.roles || '';
    }

    // On peut à présent mettre à jour nos states avec les informations récupéréess
    setAuthToken(token);
    setAuthId(id);
    setAuthRoles(roles);
    setAuthUser(user);
  };

  const resetUserAuth = () => {
    setAuthToken('');
    setAuthId(0);
    setAuthUser('');
    setAuthRoles([]);
    setAuthPermissions([]);
    setAuthImg('default_user_image.png');
  };

  /*
    Se déclenche quand le state contenant les rôles change.
    Détermine les permissions associées aux nouveaux rôles
  */
  useEffect(() => {
    // Retourne un tableau contenant l'ensemble des permissions accordées au User connecté
    const getPermissionsForRoles = () => {
      // Contient le cache des rôles contenant les informations de tous ces derniers
      const rolesCollection = cache['https://ti-pei-gourmand.fr/api/roles'];

      // Si ce cache existe (devrait toujours être vraie)
      if (rolesCollection ?? false) {
        // Contiendra toutes les permissions accordées au User connecté. Set empêche les doublons
        const permissionsSet = new Set();

        // Pour chaque rôle contenu dans mon tableau
        authRoles.forEach((authRole) => {
          // Je récupère les informations de ce dernier dans le cache des rôles
          const roleName = rolesCollection['hydra:member'].find((role) => role.name === authRole);
          // Si j'ai bien trouvé ce dernier dans le cache des rôles
          if (roleName) {
            // Pour chaque permission associée
            roleName.rolePermissions.forEach((rolePermission) => {
              // J'ajoute ces dernières dans l'ensemble des permissions
              permissionsSet.add(rolePermission.permission.name);
            });
          }
        });

        // Je convertis et retourne l'ensemble en tableau
        return Array.from(permissionsSet);
      }
      return [];
    };

    // Si les authRoles existent (empêche d'effectuer la logique au montage initial)
    if (authRoles.length > 0) {
      // Mets à jour les permissions accordées au User connecté
      setAuthPermissions(getPermissionsForRoles());
    }
  }, [authRoles, cache['https://ti-pei-gourmand.fr/api/roles']]);

  /*
    Se déclenche quand le state contenant les permissions change.
    Fin du processus de récupération des nouvelles informations de l'utilisateur connecté
  */
  useEffect(() => {
    // Si les authPermissions existent (empêche d'effectuer la logique au montage initial)
    if (authPermissions.length > 0) {
      // Le processus de récupération des informations est terminé, je retire le loading
      setIsLoading(false);
    }
  }, [authPermissions]);

  /*
    Se déclenche au montage initial de l'application.
    Permet de déclencher le processus de récupération des informations du User connecté
  */
  useEffect(() => {
    // Je récupère le token d'id dans le LocalStorage
    const storedToken = localStorage.getItem('authToken');

    // Si il existe je peux lancer le processus
    if (storedToken) {
      updateUserAuth(storedToken, 0, [], null, true);
    } else {
      setIsLoading(false);

      // Redirection vers la page de connexion
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const getUserImg = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      // Requête l'API récupérant la liste des entités
      const { data } = await fetchData(`https://ti-pei-gourmand.fr/api/users/image/${authId}`, options);
      setAuthImg(data.imageName);
    };

    if (authId !== 0) {
      getUserImg();
    }
  }, [authId]);

  return {
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
  };
};

// Hook permettant d'accéder aux informations partagées par le context ApiContext
export const useApi = () => {
  const {
    isLoading,
    setIsLoading,
    cache,
    updateCache,
    resetCache,
    errors,
    setErrors,
    fetchData,
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
    isArraySuperset,
    updateAssociativeEntity,
  } = useContext(ApiContext);
  return {
    isLoading,
    setIsLoading,
    cache,
    updateCache,
    resetCache,
    errors,
    setErrors,
    fetchData,
    authToken,
    authId,
    authRoles,
    authUser,
    authImg,
    setAuthImg,
    authPermissions,
    updateUserAuth,
    resetUserAuth,
    isArraySuperset,
    updateAssociativeEntity,
  };
};

export const useRestaurant = () => {
  const { fetchRestaurantData } = useContext(RestaurantContext);
  return { fetchRestaurantData };
};

export const useModalManagement = () => {
  const { handleSuccessInModal } = useContext(ModalManagementContext);
  return { handleSuccessInModal };
};

export const useTheme = () => useContext(ThemeContext);

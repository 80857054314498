import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';

const GallerySection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const GalleryTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

const Generic = styled.div`
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 2px solid white;
`;

function GalleryOptionsFormPart({
  handleSubmit,
  setImageMemberInfos,
  entries,
  setEntries,
}) {
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  // Méthode pour gérer le changement de galleryImage sélectionné
  const handleGalleryImageChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const selectedImage = entries.galleryImages.find(
      (image) => image.id === selectedId,
    );
    setSelectedGalleryImage(selectedImage);
  };

  // Méthode pour changer la colonne d'une galleryImage
  const handleMemberFieldChange = (value, imageId, fieldName) => {
    // On crée une copie de entries
    const updatedEntries = { ...entries };
    // On trouve le membre correspondant à l'id passé
    const imageToUpdate = updatedEntries.galleryImages.find((image) => image.id === imageId);
    // On met à jour la colonne passée
    if (imageToUpdate) {
      // En modifiant imageToUpdate[fieldName] je modifie updatedEntries.galleryImages[fieldName]
      imageToUpdate[fieldName] = value;
    }
    // On met à jour le tout
    setEntries(updatedEntries);
  };

  // Méthode pour gérer le changement d'image pour un champ spécifique
  const handleImageMemberChange = (e, fieldName, imageId) => {
    const file = e.target.files[0];
    // Pour chaque image du formulaire, un objet lui est dédié et il est identifié par son field
    setImageMemberInfos((prevImageMemberInfos) => ({
      ...prevImageMemberInfos,
      [fieldName]: {
        imageId,
        imageFile: file,
        imageName: file.name,
      },
    }));
  };

  return (
    <GallerySection>
      <GalleryTitle>Section Gallery</GalleryTitle>
      <Form onSubmit={handleSubmit}>
        <Generic>
          <FormGroup className="mb-3" controlId="flexSwitchCheckChecked">
            <Form.Check
              type="switch"
              id="flexSwitchCheckChecked"
              label="Afficher cette section ?"
              checked={entries.isEnabled}
              onChange={(e) => setEntries({ ...entries, isEnabled: e.target.checked })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamTitle">Titre</FormLabel>
            <FormControl
              type="text"
              id="teamTitle"
              value={entries.title}
              onChange={(e) => setEntries({ ...entries, title: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="teamDescription">Description</FormLabel>
            <FormControl
              as="textarea"
              rows={5}
              id="teamDescription"
              value={entries.description}
              onChange={(e) => setEntries({ ...entries, description: e.target.value })}
            />
          </FormGroup>
        </Generic>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="teamMembers" className="mt-2">Images affichées</FormLabel>
          <Form.Select
            id="teamBackgroundImg"
            onChange={handleGalleryImageChange}
            defaultValue=""
          >
            <option value="" disabled>Choisissez une image</option>
            {entries.galleryImages.map((galleryImage, index) => (
              <option key={parseInt(galleryImage.id, 10)} value={galleryImage.id}>{`Image ${index + 1}`}</option>
            ))}
          </Form.Select>
        </FormGroup>
        {selectedGalleryImage && (
          <>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="galleryImageDescription">Description</FormLabel>
              <FormControl
                type="text"
                id="galleryImageDescription"
                value={selectedGalleryImage.description}
                onChange={(e) => handleMemberFieldChange(e.target.value, selectedGalleryImage.id, 'description')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel htmlFor="memberImage">Image</FormLabel>
              <Form.Control
                type="file"
                id="memberImage"
                className="bg-dark"
                onChange={(e) => handleImageMemberChange(e, `imageName_${selectedGalleryImage.id}`, selectedGalleryImage.id)}
              />
            </FormGroup>
          </>
        )}
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </GallerySection>
  );
}

GalleryOptionsFormPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setImageMemberInfos: PropTypes.func.isRequired,
  entries: PropTypes.shape({
    isEnabled: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    galleryImages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
  }).isRequired,
  setEntries: PropTypes.func.isRequired,
};

export default GalleryOptionsFormPart;

import React, { useState } from 'react';
import GenericTable from '../../Generic/GenericTable';

function TableList() {
  // State permettant de définir les informations des Menus à afficher
  const [displayedColumns, setDisplayedColumns] = useState({
    number: true,
    isOccupied: true,
    urlIdentifier: true,
  });

  return (
    <GenericTable
      name="tables"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
    />
  );
}

export default TableList;

import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';

const AboutUsSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const AboutUsTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

function AboutUsOptionsFormPart({
  handleSubmit,
  handleImageChange,
  entries,
  setEntries,
}) {
  return (
    <AboutUsSection>
      <AboutUsTitle>Section À propos</AboutUsTitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="flexSwitchCheckChecked">
          <Form.Check
            type="switch"
            id="flexSwitchCheckChecked"
            label="Afficher cette section ?"
            checked={entries.isEnabled}
            onChange={(e) => setEntries({ ...entries, isEnabled: e.target.checked })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="aboutUsTitle">Titre</FormLabel>
          <FormControl
            type="text"
            id="aboutUsTitle"
            value={entries.title}
            onChange={(e) => setEntries({ ...entries, title: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="aboutUsSubtitle">Sous-titre</FormLabel>
          <FormControl
            type="text"
            id="aboutUsSubtitle"
            value={entries.subtitle}
            onChange={(e) => setEntries({ ...entries, subtitle: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="aboutUsDescription">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={5}
            id="aboutUsDescription"
            value={entries.description}
            onChange={(e) => setEntries({ ...entries, description: e.target.value })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="aboutUsMainImg">Image principale</FormLabel>
          <Form.Control
            type="file"
            id="aboutUsMainImg"
            className="bg-dark"
            onChange={(e) => handleImageChange(e, 'mainImg')}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="aboutUsSecondaryImg">Image secondaire</FormLabel>
          <Form.Control
            type="file"
            id="aboutUsSecondaryImg"
            className="bg-dark"
            onChange={(e) => handleImageChange(e, 'secondaryImg')}
          />
        </FormGroup>
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </AboutUsSection>
  );
}

AboutUsOptionsFormPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  entries: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    isEnabled: PropTypes.bool,
  }).isRequired,
  setEntries: PropTypes.func.isRequired,
};

export default AboutUsOptionsFormPart;

const getImagePath = (section) => {
  switch (section) {
    case 'presentation':
      return 'presentationOptions';
    case 'special':
      return 'specialOptions';
    case 'team':
      return 'teamOptions';
    default:
      return '';
  }
};

export default getImagePath;
